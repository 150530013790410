const langs = {
  Navbar: {
    languages: {
      en: "English",
      pt: "Portuguese",
    },

    about: "Sobre",
    events: "Eventos",
    members: "Membros",
    team: "Equipa",
    home: "Início",
    projects: "Projetos",
    contact: "Contacto",
  },

  Home: {
    name: "Início",
    title: "WE ARE NCGM",
    button_text: "Conhece-nos melhor!",
    ggj_button: "Participa na GGJ!",
    recruitment_button: "Estamos a recrutar!",
    we_are_passionate_about: 'As nossas paixões',
    what_we_do_label1: 'Apresentação de vários ferramentas ligadas às áreas de interesse do núcleo (Blender, UE4, Unity,…)',
    what_we_do_label2: 'Evento mensal, aberto à comunidade, para todos os interessadas na área de game development, cujos principais objetivos são o convívio, partilha de projetos e discussão de ideias.',
    what_we_do_label3: 'Hackathon de desenvolvimento de jogos, com uma duração de 48 horas e realizada a nível mundial nos primeiros meses do ano.',
    our_interests: {
      cgraphics: 'Computação Gráfica',
      gamedev: 'Desenvolvimento de Jogos',
      interaction: 'Design de Interfaces',
      multimedia: 'Multimédia'
    },
    interest_descriptions: {
      cgraphics: "Esta vasta e excitante área dentro da ciência da computação está em constante evolução e é a base da fotografia digital, filmes e video jogos",
      gamedev: "Desde os mais simples platformer 2D indies aos mais complexos jogos de estratégia AAA, no coração do NCGM reside um profundo amor por Game Dev",
      interaction: "O desenvolvimento de uma ponte esteticamente agradável, limpa, e sem qualquer fricção entre o utilizador e o dispositivo é uma arte que procuramos aprender",
      multimedia: "No NCGM, reconhecemos a importância da interação audiovisual, e da liberdade artística que esta possibilita, em qualquer tipo de aplicação",
    },
    eventPanel: {
      title: 'Game Dev Meet @ Porto',
      subtitle: 'Evento',
      description: {
        p1: "O nosso evento regular de maior calibre é o Game Dev Meet @Porto, um encontro mensal de criadores amadores, profissionais ou de interessados em jogos ou experiências multimédia.",
        p2: "Este evento tem como objetivo fomentar a interação entre criadores e admiradores, e constitui um espaço perfeito para partilhar projetos, conviver e discutir ideias.",
        p3: "O NCGM começou a organizar este evento em maio de 2017, contando com uma média de 50 participantes por edição!",
        p4: "A entrada é livre, e não requer inscrição prévia! Desde designers de som a designers de imagem, escritores a artistas, programadores ou apenas curiosos, qualquer um pode participar, mostrar o seu trabalho, aprender, ensinar, e mais importante ainda, divertir-se!"
      },
      button_text: 'Ver todos os Eventos'
    },
    projectPanel: {
      title: 'Feup Mayhem',
      subtitle: 'Projeto',
      description: {
        p1: "Um jogo para dois jogadores baseado no Gun Mayhem.",
        p2: "O tema geral é a FEUP, e as personagens representam diferentes cursos de Engenharia da faculdade."
      },
      button_text: 'Ver todos os Projetos'
    },
    goals: 'Objetivos',
    who_are_we: 'Quem somos?',
    who_are_we_label: 'O Núcleo de Computação Gráfica e Multimédia é um grupo com um foco em Game Development, tendo nos últimos anos procurado abranger algumas das múltiplas vertentes de Multimédia. Atualmente, somos os organizadores do Game Dev Meet @ Porto e há já 3 anos que participamos como Jam Site na Global Game Jam, uma hackathon de desenvolvimento de jogos. Para além de eventos relativos a game development, organizamos também workshops de ferramentas transversais às esferas de atuação do núcleo, como Blender e Unity. Procuramos, assim, oferecer à comunidade em geral atividades que fomentem o crescimento das áreas de multimédia e game development a nível nacional.',
    calculate: 'Calculando...',
    ggj_date: 'Janeiro 31 - Fevereiro 2',
    ggj_see_more: 'Queres saber mais sobre a Global Game Jam?',
    ggj_check: 'Vê a nossa página',
    find: 'Encontra-nos ou contacta-nos!',
    form_name: 'Nome',
    form_textarea: 'Escreve a tua mensagem aqui...',
    form_submit: 'Enviar',
    form_sent: 'Enviado!'
  },

  About: {
    name: "Sobre",
    title: "WE SEEK TO EXCEL",
    about_us: {
      p1: "Desde a sua incepção, o NCGM procurou promover a interdisciplinaridade, explorando as sinergias, convergências e articulações possíveis entre uma multitude de áreas científicas, tecnológicas, humanísticas e artísticas. Para este efeito, temos desenvolvido inúmeros projetos e organizado e promovendo eventos dentro das suas áreas de atividade, direcionadas não só para a comunidade estudantil como também para qualquer entusiasta dessas áreas.",
    },
    Panel: {
      title: 'A juntar talentos desde 2006',
      subtitle: 'A nossa História',
      description: {
        p1: "O NCGM, é uma associação sem fins lucrativos, fundada em 2006 por estudantes com interesse nas áreas de Computação Gráfica, Desenvolvimento de Jogos e Experiências Digitais, e Interação Pessoa-Computador.",
        p2: "Inicialmente chamado NeCG, em 2017 fundiu-se com o Núcleo de Estudantes do Mestrado de Multimédia, para promover o desenvolvimento de projetos com equipas multidisciplinares e com o objetivo de ampliar o impacto de ambos núcleos nas suas comunidades. Após a oficialização desta fusão, o núcleo assumiu o nome atual de Núcleo de Computação Gráfica e Multimédia, ou NCGM.",
        p3: "Atualmente, o Núcleo é composto por cerca de 50 estudantes, sendo a maioria dos quais estudantes da Faculdade de Engenharia da Universidade do Porto, e os restantes pertencentes a outras faculdades da área do Porto.",
        p4: "Se estás interessado nas nossas áreas de atividade, não hesites em contactar-nos. Estamos sempre à procura de pessoas motivadas que partilham a nossa paixão por tudo o que é computação gráfica e multimédia!"
      },
      button_text: 'Contacta-nos'
    },
    sutitle: "NÓS FAZEMOS EVENTOS E PROJETOS EM",
  },

  Members: {
    title: "A nossa equipa",
    description: "Bla bla bla description",
    elected: "Direção 2021/2022",
    members: "Membros",
    president: "Presidente",
    treasurer: "Tesoureiro",
    vice: "Vice-Presidente",
  },

  Team: {
    name: "Equipa",
    title: "WE ARE A TEAM OF MAKERS",
    our_team:
    {
      p1: "Nós priorizamos uma abordagem prática no que toca ao desenvolvimento de novas aptitudes, adquirindo experiência e aprendendo ao fazer, o que resulta não só em mais interesse por parte dos nossos membros, como também promove a sua creatividade a habilidade de resolução de problemas.",
    },
    Panel: {
      title: '100+ Membros',
      subtitle: 'Nós somos',
      description: {
        p1: 'Com uma história relativamente recente, que começou em 2006, o NCGM tem tentado captar e dar uma plataforma útil a todos os entusiastas interessados na área da Computação Gráfica, Game Dev e Multimédia.',
        p2: 'Procuramos fomentar uma forte relação de entreajuda e partilha de conhecimento entre os nossos membros. De estudante para estudante, ou até mesmo a partir dos nossos alumni, empenhamo-nos para que se crie um melhor processo de adaptação dos mais novos a um mundo que, para muitos, pode ser bastante desafiante (ou até completamente desconhecido).',
        p3: 'Queres ser o nosso próximo membro? Inscreve-te já',
      },
      button_text: 'Junta-te a nós!'
    },
  },

  Projects: {
    name: "Projetos",
    title: "WE GET THINGS DONE",
    our_projects:
    {
      p1: "Esta página contém alguns dos projetos terminados, elaborados por membros do NCGM.",
    },
    project1Panel: {
      title: 'Website do NCGM',
      subtitle: 'Projeto',
      description: {
        p1: "O sítio acolhedor onde estamos de momento.",
        p2: "Arranja um lugar à volta da lareira.",
      },
      button_text: 'Repositório de Git',
    },
    project2Panel: {
      title: 'FEUP Mayhem',
      subtitle: 'Projeto',
      description: {
        p1: "O FEUP Mayhem é um jogo 2D platform shooter para dois jogadores inspirado primariamente por Gun Mayhem e Super Smash Bros.",
        p2: "O jogo tem lugar durante um semestre particularmente exaustivo, no qual os estudantes lutam para defender o seu curso de Engenharia.",
        p3: "Os jogadores controlam uma de 4 personagens, cada uma com uma história e habilidade especial baseada no curso a que esta pertence, que tem a capacidade de mudar o rumo da batalha instantaneamente, quando bem utilizada!",
        p4: "Estes devem usar as suas habilidades, armas e perspicácia para triunfarem sobre o seu oponente, e atirá-los para fora do mapa.",
        p5: "Trás orgulho ao teu curso, e torna-te no derradeiro Engenheiro!"
      },
      button_text: 'Repositório de Git',
    },
  },

  Events: {
    name: "Eventos",
    title: "WE CONNECT TALENTED PEOPLE",
    our_events:
    {
      p1: "O nosso Núcleo organiza workshops e palestras com o intuito de melhorar as capacidades e aptitudes dos seus membros e associados, fornecendo meios para que estes possam desenvolver projetos e atividades de valor para si e para a comunidade em que se inserem.",
    },
    event1Panel: {
      title: 'Game Dev Meet @ Porto',
      subtitle: 'Evento',
      description: {
        p1: "O nosso evento regular de maior calibre é o Game Dev Meet @Porto, um encontro mensal de criadores amadores, profissionais ou de interessados em jogos ou experiências multimédia.",
        p2: "Este evento tem como objetivo fomentar a interação entre criadores e admiradores, e constitui um espaço perfeito para partilhar projetos, conviver e discutir ideias.",
        p3: "O NCGM começou a organizar este evento em maio de 2017, contando com uma média de 50 participantes por edição!",
        p4: "A entrada é livre, e não requer inscrição prévia! Desde designers de som a designers de imagem, escritores a artistas, programadores ou apenas curiosos, qualquer um pode participar, mostrar o seu trabalho, aprender, ensinar, e mais importante ainda, divertir-se!"
      },
      button_text: 'Aprende mais'
    },
    event2Panel: {
      title: 'Global Game Jam',
      subtitle: 'Evento',
      description: {
        p1: "Participa na maior game jam do mundo! Desenvolve um jogo do início ao fim em 48 horas, sozinho ou em equipa. No início da jam é dado um tema aos participantes, com base no qual estes têm de criar o seu jogo!",
        p2: "O NCGM é anfitrião de um jam site na FEUP, onde equipas se reúnem para participar na competição!",
        p3: "A GGJ geralmente ocorre no final de Janeiro; a data precisa pode ser encontrada no site oficial da Global Game Jam.",
      },
      button_text: 'Visita o website da GGJ!'
    },
  },

  Footer: {
    message: "Segue-nos nas redes sociais e fica a par de tudo.",
  },

  Contact: {
    name: "Contacto",
    title: "FALA CONNOSCO",
    description: "GOSTARÍAMOS MUITO DE TE CONHECER!",
    form_first_name: "Primeiro Nome",
    form_last_name: "Último Nome",
    form_textarea: "A tua mensagem",
    form_submit: "ENVIAR MENSAGEM",
    form_sent: "Enviado!",
  },

  Login: {},

  Signup: {},

  Logout: {
    profile: "Editar Perfil",
    logout: "Terminar Sessão",
  },

  Profile: {
    saveChanges: "Guardar Alterações",
    imgTooltip: "Escolhe uma imagem com o mesmo comprimento e altura",
    role1: "Membro",
    role2: "Administrador",
    role3: "Alumni",
    aboutMe: "Sobre mim:",
    descriptionPlaceholder: "Escreve a tua descrição pessoal aqui...",
    editTooltip: "Editar",
    memberSince: "Membro desde:",
  },

  Ggj: {
    name: "Global Game Jam",
    title: "Junta-te a nós na Global Game Jam",
    about_ggj: {
      p1: "Junta-te a nós e fica a par de todas a noticias sobre a Global Game Jam deste ano. Vamos participar em conjunto com várias outras equipas portuguesas ara criar um jogo em 48 horas.",
      p2: "Devido ao Covid e às restrições atuais em Portugal, a jam terá lugar em formato online. Continue a ler para sbaer mais. ",
    },
    Panel: {
      title: 'A inspirar criadores de jogos desde 2008',
      subtitle: 'Global Game Jam',
      description: {
        p1: "A Global Game Jam terá lugar entre dia 20 e 30 de janeiro. Num dia ainda por escolher, todas as equipas portuguesas terão 48 horas para criar o melhore jogo que conseguirem sobre um tema, apresentando-o de seguida ao público. Todos os jogos devem esta rligados ao tema escolhido. No entanto, o tema é secreto e só será revelado muito proximo da jam.",
        p2: "De forma a cumprir com as restrições impostas, a jam decorrerá em regime 'on-line'. Os participantes terão aceso a um servidor de Discord privado onde poderão juntar-se à sua equipa e participar na jam. ",
      },
    },
    sutitle: "Meet the team!",
  },

  // GGJ: {
  //   info: "Links Diretos e Informação",
  //   officialWebsite: "Página Oficial:",
  //   datesLabel: "Datas:",
  //   dates: "31 janeiro - 02 february, 2020",
  //   jameSitesLabel: "Locais:",
  //   jamesites: "Lista das localizações da Global Game Jam",
  //   generalContactLabel: "Contacto Principal:",
  //   officialHashtagLabel: "Hashtag oficial:",
  //   officialHashtag: "#GGJ20",
  //   countdown: "Contagem decrescente",
  //   ourSite: "Sobre o nosso Site",
  //   whatDoYouNeed: "O que precisas de fazer?",
  //   whatDoWeExcept: "O que esperamos de ti?",
  //   applications: "Candidaturas",
  // },

  UnderConstruction: {
    title: "PÁGINA EM CONSTRUÇÃO",
    home: "> Início <",
  },

  err404: {
    description: "DESCULPA, ESTA PÁGINA NÃO ESTÁ DISPONÍVEL",
    message:
      "Whoopsie! A página que estás à procura não foi encontrada. Podes ter inserido o endereço errado ou a página pode ter sido movida.",
    button_send_back: "VOLTAR PARA TRÁS",
  },
}

export default langs
