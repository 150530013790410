import React, { useRef, useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { Tooltip } from "reactstrap";

import profileImgDefault from "../../assets/images/no-image-available.png";
import Footer from "../../components/footer";
import AdminBackOffice from "./AdminBackOffice";
import "./Profile.css";

import { updateUser } from "../../actions/authActions";
import * as MEMBER_ROLES from "../../enumerations/memberRoles";
import * as LINK_TYPES from "../../enumerations/linkTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faTimes,
  faEnvelope,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

var classNames = require("classnames");

const Profile = (props) => {
  const descriptionTextarea = useRef(null);

  const [file, setProfileImg] = useState(profileImgDefault);
  const [newProfileImg, setNewProfileImg] = useState(null);
  const [description, setDescription] = useState("");
  const [isNameSetting, setIsNameSetting] = useState(false);
  const [isDescriptionSetting, setIsDescriptionSetting] = useState(false);
  const [username, setUsername] = useState(null);
  const [role, setRole] = useState(null);
  const [linkOptions, setLinkOptions] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [changeTooltipOpen, setChangeTooltipOpen] = useState(false);
  const [linksArray, setLinksArray] = useReducer(
    (linksArray, { type, value }) => {
      switch (type) {
        case "add":
          setLinkOptions(false);
          return [...linksArray, value];
        case "remove":
          return linksArray.filter((_, index) => index !== value);
        case "update":
          return linksArray.map((link, index) => {
            if (index === value.index) {
              value.type === 1
                ? (link.type = value.value)
                : (link.value = value.value);
              return link;
            } else {
              return link;
            }
          });
        case "setting":
          return linksArray.map((link, index) => {
            if (index === value.index) {
              link.isSetting = true;
              return link;
            } else {
              return link;
            }
          });
        case "finish-setting":
          return linksArray.map((link, index) => {
            link.isSetting = false;
            return link;
          });
        default:
          return linksArray;
      }
    },
    []
  );

  const startNameSetting = () => {
    console.log("Pen clicked");
    setIsNameSetting(true);
    document.querySelector("#username input").focus();
  };

  const stopNameSetting = () => {
    console.log("Lost focus handler");
    setIsNameSetting(false);
    setNameChanged(true);

    const input = document.querySelector("#username input");
    const name = input.value;

    if (!name.length) {
      input.value = props.user.name;
      setUsername(props.user.name);
      return;
    }
    setUsername(name);
  };

  const startDescriptionSetting = () => {
    console.log("Pen clicked");
    setIsDescriptionSetting(true);
    setDescriptionChanged(true);
  };

  const stopDescriptionSetting = () => {
    console.log("Lost focus handler");
    setIsDescriptionSetting(false);

    let descrip = document.querySelector("#description").value;

    setDescription(descrip);
  };

  const noEnter = (e) => {
    if (e.key === "Enter") {
      stopNameSetting();
      return e.preventDefault();
    } else {
      return null;
    }
  };

  const toggleTooltip = () => setChangeTooltipOpen(!changeTooltipOpen);

  const readURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileImg(URL.createObjectURL(e.target.files[0]));
      setNewProfileImg(e.target.files[0]);
      console.log(e.target.files[0]);
      console.log("file: " + e.target.files[0].name);
    }
  };

  const getLinksArray = () => {
    return linksArray
      .filter((link) => link.value.length !== 0)
      .map((link) => {
        return { linkType: link.type, linkValue: link.value };
      });
  };

  const saveChanges = () => {
    setLinksArray({ type: "finish-setting", value: null });
    setNameChanged(false);
    setDescriptionChanged(false);

    props.updateUser(props.user.id, {
      name: username,
      bio: description,
      image: newProfileImg,
      links: getLinksArray(),
    });
  };

  useEffect(() => {
    if (!props.isAuthenticated) {
      props.history.push("/home");
    } else {
      setUsername(props.user.name);
      setRole(props.user.role);
      setDescription(props.user.bio);
      setProfileImg(`/api/members/${props.user.id}/picture`);

      console.log("links");
      console.log(props.user.links);
      if (props.user.links) {
        props.user.links.forEach((link) =>
          setLinksArray({
            type: "add",
            value: {
              type: link.linkType,
              value: link.linkValue,
              isSetting: false,
            },
          })
        );

        if (props.user.links.length === 0) {
          setLinksArray({
            type: "add",
            value: {
              type: LINK_TYPES.GITHUB,
              value: "github.com/username",
              isSetting: false,
            },
          });
          setLinksArray({
            type: "add",
            value: {
              type: LINK_TYPES.EMAIL,
              value: "youremail@gmail.com",
              isSetting: false,
            },
          });
        }
      }
    }
  }, [props.user]);

  useEffect(() => {
    if (!props.isAuthenticated) {
      props.history.push("/home");
    }
  }, [props.history, props.isAuthenticated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Row className="one-page-plus-minus-footer profile-bg-color header-spacing text-white">
        <Col className="col-lg-6 col-md-6 col-sm-12 col-12 text-right">
          <div className="lhs-alignment">
            <div className="image-name-container">
              <div
                className={classNames(
                  "img-container",
                  role === MEMBER_ROLES.MEMBER
                    ? "tag-1"
                    : role === MEMBER_ROLES.DIRECTION
                    ? "tag-2"
                    : "tag-3"
                )}
              >
                <img
                  className={classNames(
                    "profile-img",
                    role === MEMBER_ROLES.MEMBER
                      ? "tag-1"
                      : role === MEMBER_ROLES.DIRECTION
                      ? "tag-2"
                      : "tag-3"
                  )}
                  id="userphoto"
                  src={file}
                  onError={() => setProfileImg(profileImgDefault)}
                  alt="img-profile"
                />
                <div className="change-image-button">
                  <div className="mt-2 icon ">
                    <label htmlFor="file-upload">
                      <FontAwesomeIcon id="camera-icon" icon={faCamera} />
                      <Tooltip
                        placement="bottom"
                        isOpen={changeTooltipOpen}
                        target="camera-icon"
                        className="tooltip-class"
                        toggle={toggleTooltip}
                      >
                        <Translate
                          component="div"
                          content="Profile.imgTooltip"
                        />
                      </Tooltip>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => readURL(e)}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center name-text-edit-container">
                <h4 className="ml-2" id="username">
                  <input
                    className="form-input"
                    disabled={!isNameSetting}
                    type="text"
                    autoFocus
                    defaultValue={username}
                    placeholder={username}
                    onKeyPress={(e) => noEnter(e)}
                    onBlur={() => stopNameSetting()}
                  />
                </h4>
                <FontAwesomeIcon
                  className={
                    "align-right-middle change-input " +
                    (nameChanged ? "active" : "")
                  }
                  onClick={() => startNameSetting()}
                  icon={faPen}
                />
              </div>
              {role === MEMBER_ROLES.MEMBER && (
                <div className="tag-1">
                  <Translate component="div" content="Profile.role1" />
                </div>
              )}
              {role === MEMBER_ROLES.DIRECTION && (
                <div className="tag-2">
                  <Translate component="div" content="Profile.role2" />
                </div>
              )}
              {role === MEMBER_ROLES.ALUMNI && (
                <div className="tag-3">{role}</div>
              )}
            </div>
            <div className="member-since-container">
              <Translate
                component="div"
                className="same-line"
                content="Profile.memberSince"
              />
              <div className="same-line"> September, 2018</div>
            </div>
          </div>
        </Col>
        <Col className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="information-container">
            <div className="mb-3">
              <Translate
                component="div"
                className="same-line"
                content="Profile.aboutMe"
              />
              <FontAwesomeIcon
                id="description-pen-icon"
                className={
                  "change-input float-right " +
                  (descriptionChanged ? "active" : "")
                }
                onClick={() => startDescriptionSetting()}
                icon={faPen}
              />
            </div>
            <Translate
              component="textarea"
              ref={descriptionTextarea}
              disabled={!isDescriptionSetting}
              className="form-control form-input profile-textarea"
              id="description"
              name="description"
              rows="11"
              maxLength="530"
              required
              attributes={{ placeholder: "Profile.descriptionPlaceholder" }}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              onBlur={() => stopDescriptionSetting()}
            />
          </div>
          <div className="personal-links-container">
            <ul className="my-2">
              {linksArray.map((link, index) => (
                <li key={index}>
                  <Row className="personal-link">
                    {linksArray.length > 2 && index >= 2 && (
                      <Col className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <FontAwesomeIcon
                          className="remove-personal-link-button"
                          onClick={(e) =>
                            setLinksArray({
                              type: "remove",
                              value: index,
                            })
                          }
                          icon={faTimes}
                        />
                      </Col>
                    )}
                    <Col
                      className={
                        "col-1 col-sm-2 col-md-2 col-lg-2" +
                        (linksArray.length > 2 && index >= 2
                          ? ""
                          : " offset-sm-1 offset-1")
                      }
                    >
                      {link.type === LINK_TYPES.EMAIL && (
                        <FontAwesomeIcon
                          className="profile-link-icon"
                          icon={faEnvelope}
                        />
                      )}
                      {link.type === LINK_TYPES.GITHUB && (
                        <FontAwesomeIcon
                          className="profile-link-icon"
                          icon={faGithub}
                        />
                      )}
                      {link.type === LINK_TYPES.LINKEDIN && (
                        <FontAwesomeIcon
                          className="profile-link-icon"
                          icon={faLinkedin}
                        />
                      )}
                      {link.type === LINK_TYPES.FACEBOOK && (
                        <FontAwesomeIcon
                          className="profile-link-icon"
                          icon={faFacebookSquare}
                        />
                      )}
                      {link.type === LINK_TYPES.INSTAGRAM && (
                        <FontAwesomeIcon
                          className="profile-link-icon"
                          icon={faInstagram}
                        />
                      )}
                    </Col>
                    <Col className="offset-1 offset-sm-0 col-7 col-sm-7 col-md-7 col-lg-7 link-url">
                      <input
                        className="form-input profile-text-input"
                        disabled={!link.isSetting}
                        onChange={(e) =>
                          setLinksArray({
                            type: "update",
                            value: {
                              type: 2,
                              index: index,
                              value: e.target.value,
                            },
                          })
                        }
                        defaultValue={link.value}
                      />
                    </Col>
                    <Col className="col-1 col-sm-2 col-md-1 col-lg-2 edit-link">
                      <FontAwesomeIcon
                        className={
                          "change-input " + (link.isSetting ? "active" : "")
                        }
                        onClick={() =>
                          setLinksArray({
                            type: "setting",
                            value: {
                              index: index,
                            },
                          })
                        }
                        icon={faPen}
                      />
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
            {linksArray.length < 5 && (
              <div>
                <FontAwesomeIcon
                  className="add-more-button"
                  onClick={() => setLinkOptions(!linkOptions)}
                  icon={faPlus}
                />
                {linkOptions && (
                  <div className="mt-2 mb-0">
                    <FontAwesomeIcon
                      className="profile-link-icon add-more-icon"
                      onClick={() =>
                        setLinksArray({
                          type: "add",
                          value: {
                            type: LINK_TYPES.EMAIL,
                            value: "",
                            isSetting: true,
                          },
                        })
                      }
                      icon={faEnvelope}
                    />
                    <FontAwesomeIcon
                      className="profile-link-icon add-more-icon"
                      onClick={() =>
                        setLinksArray({
                          type: "add",
                          value: {
                            type: LINK_TYPES.GITHUB,
                            value: "",
                            isSetting: true,
                          },
                        })
                      }
                      icon={faGithub}
                    />
                    <FontAwesomeIcon
                      className="profile-link-icon add-more-icon"
                      onClick={() =>
                        setLinksArray({
                          type: "add",
                          value: {
                            type: LINK_TYPES.LINKEDIN,
                            value: "",
                            isSetting: true,
                          },
                        })
                      }
                      icon={faLinkedin}
                    />
                    <FontAwesomeIcon
                      className="profile-link-icon add-more-icon"
                      onClick={() =>
                        setLinksArray({
                          type: "add",
                          value: {
                            type: LINK_TYPES.FACEBOOK,
                            value: "",
                            isSetting: true,
                          },
                        })
                      }
                      icon={faFacebookSquare}
                    />
                    <FontAwesomeIcon
                      className="profile-link-icon add-more-icon"
                      onClick={() =>
                        setLinksArray({
                          type: "add",
                          value: {
                            type: LINK_TYPES.INSTAGRAM,
                            value: "",
                            isSetting: true,
                          },
                        })
                      }
                      icon={faInstagram}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <Translate
            component="button"
            className="btn btn-danger save-changes-button"
            onClick={() => saveChanges()}
            content="Profile.saveChanges"
          />
        </Col>
      </Row>
      {props.user !== null && props.user.role === MEMBER_ROLES.DIRECTION && (
        <Row>
          <AdminBackOffice {...props} />
        </Row>
      )}
      <Footer {...props} />
    </Container>
  );
};

Profile.propTypes = {
  updateUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  user: state.auth.user,
  emails: state.members.memberEmails,
  lang: state.lang,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, body) => dispatch(updateUser(id, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
