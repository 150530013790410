import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Form, FormGroup, Input, UncontrolledAlert } from "reactstrap";
import { Link } from "react-router-dom";
import { register } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import logoIMG from "../../assets/images/logo_transparent.png";

import "./auth.css";

const Register = ({ lang, isAuthenticated, doRegister, error, history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    setMsg(error.id === "REGISTER_FAIL" ? error.msg.msg : null);

    if (error.id === null && isAuthenticated) {
      history.push(`/${lang}/home`);
    }
  }, [lang, history, error, isAuthenticated]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setMsg("Passwords doesn't match!");
      return;
    }

    doRegister({
      name,
      email,
      password,
      passwordConfirmation,
    });
  };

  return (
    <div>
      <div className="one-page back-office-bg">
        <Link className="back-office-logo" to={`/${lang}/home`}>
          <img src={logoIMG} alt="logo" />
        </Link>
        <div className="vertical-center w-100">
          <Container className="py-5 back-office-container">
            <div className="text-center text-white">
              <h1 className="mb-5 back-office-title">Signup</h1>
              <Form onSubmit={onSubmit}>
                <FormGroup className="w-100">
                  <div>
                    <Input
                      className="form-control form-input"
                      type="text"
                      id="name"
                      name="name"
                      maxLength="30"
                      required
                      placeholder="Name"
                      onChange={({ target }) => setName(target.value)}
                    />
                  </div>
                  <div className="back-office-form-spacing">
                    <Input
                      className="form-control form-input"
                      type="email"
                      id="email"
                      name="email"
                      maxLength="35"
                      required
                      placeholder="E-mail"
                      onChange={({ target }) => setEmail(target.value)}
                    />
                  </div>
                  <div className="back-office-form-spacing">
                    <Input
                      className="form-control form-input"
                      type="password"
                      id="password"
                      name="password"
                      maxLength="30"
                      required
                      placeholder="Password"
                      onChange={({ target }) => setPassword(target.value)}
                    />
                  </div>
                  <div className="back-office-form-spacing">
                    <Input
                      className="form-control form-input"
                      type="password"
                      id="repeatPassword"
                      name="repeatPassword"
                      maxLength="30"
                      required
                      placeholder="Password confirm"
                      onChange={({ target }) =>
                        setPasswordConfirmation(target.value)
                      }
                    />
                  </div>
                  <Button className="back-office-button">Register</Button>
                  <div className="new-m">
                    <div className="text">Already a member? </div>
                    <Link className="link" to={`/${lang}/login`}>
                      {" "}
                      Sign in
                    </Link>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </Container>
        </div>
        <UncontrolledAlert
          className="errors"
          color="info"
          fade={false}
          isOpen={msg != null}
          onClick={() => setMsg(null)}
        >
          {msg}
        </UncontrolledAlert>
      </div>
    </div>
  );
};

Register.defaultProps = {
  isAuthenticated: null,
};

Register.propTypes = {
  isAuthenticated: PropTypes.bool,
  doRegister: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  user: state.auth.user,
  lang: state.lang,
});

export default connect(mapStateToProps, { doRegister: register, clearErrors })(
  Register
);
