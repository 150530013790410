const LINK_TYPES = Object.freeze({
  EMAIL: "EMAIL",
  GITHUB: "GITHUB",
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  LINKEDIN: "LINKEDIN",
  TWITTER: "TWITTER",
  BEHANCE: "BEHANCE",
  OTHER: "OTHER",
});

module.exports = LINK_TYPES;
