import { GET_MEMBERS, MEMBERS_LOADING, GET_MEMBER_EMAILS, MEMBER_EMAILS_LOADING, ADD_MEMBER_EMAIL, REMOVE_MEMBER_EMAIL } from '../actions/types'

const initialState = {
  members: [],
  memberEmails: [],
  loading: false
}

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
        loading: false
      }
    case GET_MEMBER_EMAILS:
      return {
        ...state,
        memberEmails: action.payload,
        loading: false
      }
    case MEMBERS_LOADING:
    case MEMBER_EMAILS_LOADING:
      return {
        ...state,
        loading: true
      }
    case ADD_MEMBER_EMAIL:
      return {
        ...state,
        memberEmails: [action.payload, ...state.memberEmails]
      }
    case REMOVE_MEMBER_EMAIL:
      return {
        ...state,
        memberEmails: state.memberEmails.filter(item => item._id !== action.payload)
      }
    default:
      return state
  }
}

export default memberReducer