import {TOGGLE_LANGUAGE, SET_LANGUAGE} from "../actions/types";

const initialState = "pt";

const langReducer = (state = initialState, action) => {

  switch(action.type){
    case TOGGLE_LANGUAGE:
      return state === "en" ?  "pt" : "en";
    case SET_LANGUAGE:
      return action.payload === "en" || action.payload === "pt" ? action.payload : initialState;
    default:
      return state;
  }
};

export default langReducer;