import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { toggleLanguage } from "../../actions/langActions";
import counterpart from "counterpart";

const LanguageToggler = ({ lang, toggleLanguage, location, history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleLang = (e, value) => {
    if (value === lang) return;
    const newLang = lang === "en" ? "pt" : "en";
    counterpart.setLocale(newLang);

    let path = location.pathname.split("/");
    history.push(`/${newLang}/${path[path.length - 1]}`);
    toggleLanguage();
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      className="language-dropdown"
      size="sm"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle color="inherit" caret tag="a" className="nav-link">
        {lang === "en" ? "English" : "Português"}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          color="inherit"
          className={lang === "pt" ? "active" : ""}
          onClick={(e) => toggleLang(e, "pt")}
        >
          Português
        </DropdownItem>
        <DropdownItem
          color="inherit"
          className={lang === "en" ? "active" : ""}
          onClick={(e) => toggleLang(e, "en")}
        >
          English
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = ({ lang }) => ({ lang });

const mapDispatchToProps = (dispatch) => ({
  toggleLanguage: () => dispatch(toggleLanguage()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LanguageToggler);
