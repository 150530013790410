import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { UncontrolledAlert } from "reactstrap";

import Modal from "react-modal";
import MaterialTable from "material-table";

import { fetchMemberMails, registerEmail } from "../../actions/memberActions";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

Modal.setAppElement("#root");

const AdminBackOffice = (props) => {
  const [msg, setMsg] = useState(null);
  const [tableEmails, setTableEmails] = useState({
    columns: [
      {
        title: "ID",
        field: "id",
        searchable: false,
        editable: "never",
        sorting: false,
      },
      {
        title: "E-mail",
        field: "email",
        validate: (rowData) => checkRowValid(rowData),
      },
    ],
    data: [],
  });
  const [tableMembers, setTableMembers] = useState({
    columns: [
      {
        title: "Avatar",
        field: "imgUrl",
        render: (rowData) => (
          <img
            alt="profile"
            src={rowData.imgUrl}
            style={{ width: 40, borderRadius: "50%" }}
          />
        ),
        editable: "never",
        searchable: false,
        sorting: false,
      },
      {
        title: props.lang === "pt" ? "Nome de Utilizador" : "Username",
        field: "username",
        editable: "never",
      },
      {
        title: "E-mail",
        field: "email",
        editable: "never",
        searchable: false,
        sorting: false,
      },
      {
        title: props.lang === "pt" ? "Cargo" : "Role",
        field: "role",
        lookup: { 1: "Member", 2: "Administrator", 3: "Alumni" },
      },
      {
        title: props.lang === "pt" ? "Posição" : "Position",
        field: "position",
        validate: (rowData) => rowData.position.length >= 4,
      },
    ],
    data: [
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Andre",
        email: "andreesteves111@gmail.com",
        role: 1,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Filipe",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "Pinto",
        email: "andreesteves111@gmail.com",
        role: 3,
        position: "Website Developer",
      },
      {
        imgUrl: "https://avatars0.githubusercontent.com/u/7895451?s=460&v=4",
        username: "FEsteves",
        email: "andreesteves111@gmail.com",
        role: 2,
        position: "Website Developer",
      },
    ],
  });

  const localiz = {
    body: {
      emptyDataSourceMessage:
        props.lang === "pt"
          ? "Nenhum registro para exibir"
          : "No records to display",
      addTooltip: props.lang === "pt" ? "Adicionar" : "Add",
      deleteTooltip: props.lang === "pt" ? "Remover" : "Delete",
      editTooltip: props.lang === "pt" ? "Editar" : "Edit",
      editRow: {
        deleteText:
          props.lang === "pt"
            ? "Queres mesmo eliminar esta linha?"
            : "Are you sure delete this row?",
        cancelTooltip: props.lang === "pt" ? "Cancelar" : "Cancel",
        saveTooltip: props.lang === "pt" ? "Guardar" : "Save",
      },
    },
    header: {
      actions: props.lang === "pt" ? "Ações" : "Actions",
    },
    toolbar: {
      searchTooltip: props.lang === "pt" ? "Pesquisar" : "Search",
    },
    pagination: {
      labelRowsSelect: props.lang === "pt" ? "linhas" : "Rows",
      labelDisplayedRows:
        props.lang === "pt"
          ? "{count} de {from}-{to}"
          : "{from}-{to} of {count}",
      firstTooltip: props.lang === "pt" ? "Primeira página" : "First Page",
      previousTooltip:
        props.lang === "pt" ? "Página anterior" : "Previous Page",
      nextTooltip: props.lang === "pt" ? "Próxima página" : "Next Page",
      lastTooltip: props.lang === "pt" ? "Última página" : "Last Page",
    },
  };

  const checkRowValid = (row) => {
    if (row.email !== "") {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(row.email)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const data = [];
    props.emails.forEach((member) =>
      data.push({ id: member._id, email: member.email })
    );

    if (data.length !== 0) {
      setTableEmails({ columns: tableEmails.columns, data: data });
    }
  }, [props.emails, tableEmails.columns]);

  useEffect(() => {
    console.log("Fetch Member Emails");
    props.fetchMemberMails();
  }, []);

  // console.log(tableEmails);

  return (
    <Container fluid className="pb-5 bg-color-2">
      <Row>
        <Col className="profile-table table-size-1 col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
          <MaterialTable
            title={
              props.lang === "pt" ? "Emails admissíveis" : "Permitted Emails"
            }
            isLoading={tableEmails.data.length === 0}
            icons={tableIcons}
            columns={tableEmails.columns}
            data={tableEmails.data}
            localization={localiz}
            options={{
              pageSize: 10,
              toolbar: true,
              pageSizeOptions: [],
              actionsColumnIndex: -1,
              loadingType: "linear",
              search: true,
              sorting: true,
              cellStyle: { backgroundColor: "#241f29", color: "white" },
              actionsCellStyle: {
                backgroundColor: "#241f29",
                color: "#ff002f",
              },
              searchFieldStyle: { backgroundColor: "#100d10", color: "white" },
            }}
            editable={{
              onRowAdd: (newEmail) =>
                new Promise((resolve) => {
                  // TODO Add new Email to DB
                  console.log(newEmail.email);
                  props.registerEmail({ email: newEmail.email });

                  setTimeout(() => {
                    resolve();
                    setTableEmails((prevState) => {
                      const data = [...prevState.data];
                      data.push(newEmail);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newEmail, oldEmail) =>
                new Promise((resolve) => {
                  // TODO Add edit Email
                  setTimeout(() => {
                    resolve();
                    if (oldEmail) {
                      setTableEmails((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldEmail)] = newEmail;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldEmail) =>
                new Promise((resolve) => {
                  // TODO Delete Email in db
                  setTimeout(() => {
                    resolve();
                    if (oldEmail) {
                      setTableEmails((prevState) => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldEmail), 1);
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
            }}
          />
        </Col>
        <Col className="profile-table table-size-2 col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
          <MaterialTable
            title={props.lang === "pt" ? "Membros" : "Members"}
            isLoading={tableMembers.data.length === 0}
            icons={tableIcons}
            columns={tableMembers.columns}
            data={tableMembers.data}
            localization={localiz}
            options={{
              pageSize: 10,
              toolbar: true,
              pageSizeOptions: [],
              actionsColumnIndex: -1,
              loadingType: "linear",
              search: true,
              sorting: true,
              exportButton: true,
              cellStyle: { backgroundColor: "#241f29", color: "white" },
              actionsCellStyle: {
                backgroundColor: "#241f29",
                color: "#ff002f",
              },
              searchFieldStyle: { backgroundColor: "#100d10", color: "white" },
            }}
            editable={{
              onRowUpdate: (newEmail, oldEmail) =>
                new Promise((resolve) => {
                  resolve();
                }),
            }}
          />
        </Col>
      </Row>
      <UncontrolledAlert
        className="alerts"
        color={msg !== null && msg[0] === "V" ? "info" : "danger"}
        fade={false}
        isOpen={msg !== null}
        onClick={() => setMsg(null)}
      >
        {msg}
      </UncontrolledAlert>
    </Container>
  );
};

AdminBackOffice.propTypes = {
  fetchMemberMails: PropTypes.func.isRequired,
  emails: PropTypes.array,
  registerEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  emails: state.members.memberEmails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMemberMails: () => dispatch(fetchMemberMails()),
  registerEmail: (email) => dispatch(registerEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminBackOffice);
