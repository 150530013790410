import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import { Provider } from "react-redux";
import store from "../../store";
import Header from "../header";

import Home from "../../pages/home";
import About from "../../pages/about";
import Events from "../../pages/events";
import Team from "../../pages/team";
import Projects from "../../pages/projects";
import Contact from "../../pages/contact";

import Register from "../../pages/auth/Register";
import Login from "../../pages/auth/Login";
import Profile from "../../pages/profile";
// import Event from "../../pages/event";
import NotFound from "../../pages/notFound";
// import Project from "../../pages/project";

import GGJ from "../../pages/ggj";
// import UnderConstruction from "../../pages/underConstruction";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Header />
            <Switch>
              <Route exact path="/:lang?/home" component={Home} />
              <Route path="/:lang?/about" component={About} />
              <Route path="/:lang?/events" component={Events} />
              <Route path="/:lang?/team" component={Team} />
              <Route path="/:lang?/projects" component={Projects} />
              <Route path="/:lang?/contact" component={Contact} />

              <Route path="/:lang?/register" component={Register} />
              <Route path="/:lang?/login" component={Login} />
              <Route path="/:lang?/profile" component={Profile} />
              <Route path="/:lang?/ggj" component={GGJ} />
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path="/:lang?/:badURL?" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
