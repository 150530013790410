import { combineReducers } from 'redux';
import langReducer from './langReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import memberReducer from './memberReducer';

export default combineReducers({
    lang: langReducer,
    error: errorReducer,
    auth: authReducer,
    members: memberReducer
});