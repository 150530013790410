export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const DEL_USER = 'DEL_USER';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const UPDATE_USER = 'UPDATE_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const MEMBERS_LOADING = 'MEMBERS_LOADING';
export const GET_MEMBER_EMAILS = 'GET_MEMBER_EMAILS';
export const MEMBER_EMAILS_LOADING = 'MEMBER_EMAILS_LOADING';
export const ADD_MEMBER_EMAIL = 'ADD_MEMBER_EMAIL';
export const REMOVE_MEMBER_EMAIL = 'REMOVE_MEMBER_EMAIL';
export const MEMBER_PROFILE_PIC_LOADING = 'MEMBER_PROFILE_PIC_LOADING';
export const GET_PROFILE_PICTURE = 'GET_PROFILE_PICTURE';