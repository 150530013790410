import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Button, Form, FormGroup, Input, UncontrolledAlert } from "reactstrap";
import { Link } from "react-router-dom";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import PropTypes from "prop-types";
import logoIMG from "../../assets/images/logo_transparent.png";

import "./auth.css";

const Login = ({ lang, isAuthenticated, doLogin, error, user, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    setMsg(
      error.id === "LOGIN_FAIL"
        ? error.status === 500
          ? error.msg
          : error.msg.msg
        : null
    );

    if (error.id === null && isAuthenticated && user) {
      history.push(`/${lang}/home`);
    }
  }, [lang, history, user, error, isAuthenticated]);

  const onSubmit = (e) => {
    e.preventDefault();
    doLogin({
      email,
      password,
    });
  };

  return (
    <div>
      {/* TODO: Forgot Password */}
      <div className="one-page back-office-bg">
        <Link className="back-office-logo" to={`/${lang}/home`}>
          <img src={logoIMG} alt="logo" />
        </Link>
        <div className="vertical-center w-100">
          <Container className="py-5 back-office-container">
            <div className="text-center text-white">
              <h1 className="mb-5 back-office-title">LOGIN</h1>
              <Form onSubmit={onSubmit}>
                <FormGroup className="w-100">
                  <div>
                    <Input
                      className="form-control form-input"
                      type="email"
                      id="email"
                      name="email"
                      maxLength="35"
                      required
                      placeholder="E-mail"
                      onChange={({ target }) => setEmail(target.value)}
                    />
                  </div>
                  <div className="back-office-form-spacing">
                    <Input
                      className="form-control form-input"
                      type="password"
                      id="password"
                      name="password"
                      required
                      placeholder="Password"
                      onChange={({ target }) => setPassword(target.value)}
                    />
                  </div>
                  <div className="modal-link float-right">Forgot password?</div>
                  <Button className="back-office-button">Login</Button>
                  <div className="new-m">
                    <div className="text">New member? </div>
                    <Link className="link" to={`/${lang}/register`}>
                      {" "}
                      Sign up
                    </Link>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </Container>
        </div>
        <UncontrolledAlert
          className="errors"
          color="info"
          fade={false}
          isOpen={msg != null}
          onClick={() => setMsg(null)}
        >
          {msg}
        </UncontrolledAlert>
      </div>
    </div>
  );
};

Login.defaultProps = {
  isAuthenticated: null,
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  doLogin: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  user: state.auth.user,
  lang: state.lang,
});

export default connect(mapStateToProps, { doLogin: login, clearErrors })(Login);
