import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import Translate from "react-translate-component";

import bannerBackground from "../../assets/images/page-bg/bg-ncgm-404.jpg";

import Social from "../../components/social";
import Footer from "../../components/footer";
import "./NotFound.css";

const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "cover",
};

const NotFound = (props) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);

  return (
    <Container fluid>
      <Row className="one-page-plus-minus-footer">
        <Col className="intro-img type-text-3 child-center pb-5" style={bannerImg}>
          <div className="main-title-no-bg family-3-bold">404</div>
          <div className="main-description-wbg mb-3 family-3-bold">
            <Translate
              component="p"
              content="err404.description"
            />
          </div>
          <div className="main-description-wbg">
            <Translate
              component="p"
              content="err404.message"
            />
          </div>
          <div className="main-description py-2">
            <Button
              outline
              color="danger"
              className="py-2"
              onClick={() => history.goBack()}
            >
              <div className="button-text-icon mr-3">
                <FontAwesomeIcon icon={("fas", faLongArrowAltLeft)} />
              </div>
              <Translate
                component="div"
                className="button-text-icon mr-2"
                content="err404.button_send_back"
              />
            </Button>{" "}
          </div>
        </Col>
      </Row>
      <Footer {...props} />
      <Social />
    </Container>
  );
};

export default NotFound;
