import React from "react"
import { Row, Col } from "react-bootstrap"

import "./Panel.css"

import Translate from "react-translate-component"

class Panel extends React.Component {
  constructor(props) {
    super(props)
    this.imgTags = []
    this.descriptionElems = []

    this.style = {
      subtitle: {},
      title: {},
      description: {},
    }

    this.setStyles()
    this.prepImages()
    this.prepDescription()
  }

  setStyles() {
    if (this.props.style) {
      if (this.props.style.subtitle)
        this.style.subtitle = this.props.style.subtitle

      if (this.props.style.title) this.style.title = this.props.style.title

      if (this.props.style.description)
        this.style.description = this.props.style.description
    }
  }

  prepImages() {
    for (let i = 0; i < this.props.Panel.images.length - 1; i++) {
      this.imgTags.push(
        <img
          key={i + 1}
          className="mb-4"
          src={this.props.Panel.images[i]}
          alt="Event"
        ></img>
      )
    }

    this.imgTags.push(
      <img
        key={this.props.Panel.images.length}
        className="align-self-end"
        src={this.props.Panel.images[this.props.Panel.images.length - 1]}
        alt="Event"
      ></img>
    )
  }

  prepDescription() {
    for (let i = 0; i < this.props.Panel.descriptionParNum; i++) {
      this.descriptionElems.push(<Translate
        key={i}
        className="Panel-desc"
        style={this.style.description}
        component="p"
        content={`${this.props.Panel.description}.p${i + 1}`}
      />)
    }
  }

  render() {

    return (
      <div className={`Panel-container ${this.props.className}`}>
        <Row>
          <Col
            className={
              "col-12 col-sm-10 col-md-6 offset-sm-1 offset-md-0 col-xl-4 " +
              (this.props.bannerPos === "right"
                ? "Panel-img-right"
                : "Panel-img-left offset-xl-2")
            }
          >
            <div className={
              "Panel-img " +
              (this.imgTags.length === 1 ? "mt-4" : "")
            }>{this.imgTags}</div>
          </Col>
          <Col
            className={
              "col-12 col-sm-10 col-md-6 offset-sm-1 offset-md-0 " +
              (this.props.Panel.images.length > 1
                ? "col-xl-3 "
                : "col-xl-4 ") +
              (this.props.bannerPos === "right" ? "offset-xl-2" : "")
            }
          >
            <Translate
              className="Panel-subtitle"
              style={this.style.subtitle}
              component="h3"
              content={this.props.Panel.subtitle}
            />
            <Translate
              className="Panel-title"
              style={this.style.title}
              component="h2"
              content={this.props.Panel.title}
            />

            {this.descriptionElems}

            {this.props.Panel.button_text !== undefined ?
              (
                <Translate
                  className="Panel-btn btn btn-outline-danger mb-2 px-4 py-3 text-white"
                  type="button"
                  component="button"
                  onClick={this.props.Panel.buttonOnClick}
                  content={this.props.Panel.button_text}
                />
              ) : ''
            }
          </Col>
        </Row>
      </div>
    )
  }
}

export default Panel
