import React, { useState, useEffect } from "react"

import {
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { connect } from "react-redux"
import { logout } from "../../actions/authActions"
import Translate from "react-translate-component"
import PropTypes from "prop-types"

import profileImgDefault from "../../assets/images/no-image-available.png"

import "./auth.css"

const Logout = ({ logout, user, lang }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [profileImg, setProfileImg] = useState(`/api/members/${user.id}/picture`)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  useEffect(() => {
    setProfileImg(`/api/members/${user.id}/picture`)
  }, [user])

  return (
    <Dropdown className="user-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="inherit" className="header-profile-link">
        <img id="header-profile" alt="profile" src={profileImg} onError={() => setProfileImg(profileImgDefault)} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem color="inherit" header>
          Logged in as:
        </DropdownItem>
        <DropdownItem color="inherit" header>
          {user.name}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem color="inherit">
          <LinkContainer to={`/${lang}/profile`}>
            <NavLink className="no-padding">
              <Translate content="Logout.profile" />
            </NavLink>
          </LinkContainer>
        </DropdownItem>
        <DropdownItem onClick={logout} color="inherit">
          <Translate content="Logout.logout" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth, lang }) => ({
  user: {
    id: auth.user.id,
    name: auth.user.name,
    picturePath: auth.user.picturePath,
  },
  lang,
})

export default connect(mapStateToProps, { logout })(Logout)
