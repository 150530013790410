import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import Translate from "react-translate-component"
import LanguageToogler from "../lang/language-toogler.js"
import ncgmLogo from "../../assets/images/logo_transparent.png"
import {
  languageWrap,
  mapStateToProps,
  mapDispatchToProps,
} from "../../components/lang/language-wrap"
import "./Footer.css"

const LinkComponent = ({ lang, page, content }) => {
  return (
    <Col className="col-6 col-sm-6 col-md-6 col-lg-6">
      <Link className="mb-1" to={`/${lang}/${page}`}>
        <Translate component="div" content={content} />
      </Link>
    </Col>
  )
}

const SocialLinkComponent = ({ href, icon }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="link-spacing"
    >
      <span className="fa-stack">
        <FontAwesomeIcon
          className="fab fa-stack-1x cl-social"
          icon={icon}
        />
      </span>
    </a>
  )
}

const Footer = (props) => {
  useEffect(() => {
    languageWrap(props)
  }, [])

  return (
    <div className="footer-container">
      <div className="container-fluid align-items-center">
        <Row className="footer-padding">
          <Col className="col-12 col-sm-12 col-md-12 col-lg-6 height-100 align-items-center d-flex px-0 mobile-spacing">
            <div className="logo-text mr-4">
              <a href="mailto:necg@fe.up.pt" rel="noopener noreferrer">
                <img id="footer-logo" src={ncgmLogo} alt="ncgm logo" />
              </a>
            </div>
            <div className="footer-text logo-text">
              <p className="mb-1">
                Núcleo de Computação Gráfica e Multimédia
              </p>
              <p className="mb-1">
                FEUP - Faculdade de Engenharia da Universidade do Porto
              </p>
              <p className="mb-1">Departamento de Engenharia Informática</p>
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-3 height-100 align-items-top footer-text mobile-spacing">
            <Row className="w-100 sitemap">
              <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                <p className="footer-title">SITEMAP</p>
              </Col>
              <LinkComponent lang={props.lang} page="home" content="Home.name" />
              <LinkComponent lang={props.lang} page="team" content="Team.name" />
              <LinkComponent lang={props.lang} page="about" content="About.name" />
              <LinkComponent lang={props.lang} page="projects" content="Projects.name" />
              <LinkComponent lang={props.lang} page="events" content="Events.name" />
              {/* Uncomment when contacts page backend is working  */}
              {/* <LinkComponent lang={props.lang} page="contact" content="Contact.name" /> */}
              <Col className="col-6 col-sm-6 col-md-6 col-lg-6 pt-1">
                {/* Uncomment when login page backend is working  */}
                {/* <Link className="mb-1 " to={`/${props.lang}/login`}>
                  Login
                </Link> */}
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-3 height-100 align-items-top footer-text">
            <p className="footer-title">SOCIAL MEDIA</p>
            <Translate
              component="p"
              className="middle-spacing"
              content="Footer.message"
            />
            <div className="footer-link">
              <SocialLinkComponent href="https://www.facebook.com/ncgmfeup/" icon={faFacebookSquare} />
              <SocialLinkComponent href="https://www.linkedin.com/company/necgm/" icon={faLinkedin} />
              <SocialLinkComponent href="https://www.instagram.com/ncgmfeup/" icon={faInstagram} />
              <SocialLinkComponent href="https://www.youtube.com/channel/UC5zqeMTr3AY-jS3C239YTEg" icon={faYoutube} />
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-12 footer-text mb-0 line-top  align-items-center d-flex">
            <div className="logo-text mb-0">
              <p className="mb-0">© 2022 NCGM. All rights reserved.</p>
            </div>
            <div className="logo-text ml-auto">
              <LanguageToogler />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
