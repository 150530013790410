import React, { useRef, useEffect } from "react"
import Translate from "react-translate-component"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import bannerBackground from "../../assets/images/page-bg/bg-ncgm-events.svg"

import Footer from "../../components/footer"
import Social from "../../components/social"
import "./Events.css"

import Panel from "../../components/panel"
import SimpleText from "../../components/simple-text"

import event1Panel from "../../assets/images/events/gdm-panel.png"
import event2Panel from "../../assets/images/events/globalgamejam-panel.jpg"

const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "contain",
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)

const Events = (props) => {
  const firstView = useRef(null)
  const executeScroll = () => scrollToRef(firstView)

  const oureventsSimpleTextProps = {
    description: "Events.our_events",
    descriptionParNum: 1,
  }

  const event1PanelProps = {
    images: [event1Panel],
    title: "Events.event1Panel.title",
    subtitle: "Events.event1Panel.subtitle",
    description: "Events.event1Panel.description",
    descriptionParNum: 4,
    // button_text: "Events.event1Panel.button_text",
    // buttonOnClick: () => history.push(`/${props.lang}/contact`),
  }

  const event2PanelProps = {
    images: [event2Panel],
    title: "Events.event2Panel.title",
    subtitle: "Events.event2Panel.subtitle",
    description: "Events.event2Panel.description",
    descriptionParNum: 3,
    button_text: "Events.event2Panel.button_text",
    buttonOnClick: () => window.location.href = "https://globalgamejam.org/",
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container fluid>
      <Row className="one-page-plus team-bg-color">

        <Col className="col-lg-6 col-12 col-sm-12 events-banner">

          <div className="main-title-no-bg">
            <Translate
              component="div"
              className="main-title"
              content="Events.title"
            />
          </div>

          <div className="main-description-no-bg">
            <div>
              <SimpleText
                simpletext={oureventsSimpleTextProps}
              ></SimpleText>
            </div>
          </div>
        </Col>

        <a className="scroll-icon" onClick={executeScroll}>
          <FontAwesomeIcon icon={("fas", faChevronDown)} />
        </a>

        <Col className="col-lg-6 events-banner-img">
          <div className="w-100 h-100" style={bannerImg}></div>
        </Col>
      </Row>

      <Row ref={firstView} className="bg-color-2 py-5">
        <Col>
          <Panel
            className="mb-4"
            Panel={event1PanelProps}
            bannerPos="left"
          ></Panel>
        </Col>

        <Col className="col-12 col-sm-12">
          <Panel
            className="my-3"
            Panel={event2PanelProps}
            bannerPos="right"
          />
        </Col>
      </Row>

      <Footer {...props} />
      <Social />
    </Container>
  )
}

export default Events
