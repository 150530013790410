import React, { useState, useEffect } from "react"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavLink from "react-bootstrap/NavLink"
import { LinkContainer } from "react-router-bootstrap"
import { NavItem } from "reactstrap"
import { mapStateToProps } from "../lang/language-wrap"

import { withRouter } from "react-router"
import { connect } from "react-redux"
import { compose } from "redux"

import Logout from "../../pages/auth/Logout"
import logoIMG from "../../assets/images/logo_transparent.png"
import Translate from "react-translate-component"

import "./Header.css"
import counterpart from "counterpart"
import en from "../lang/en.js"
import pt from "../lang/pt.js"

counterpart.registerTranslations("en", en)
counterpart.registerTranslations("pt", pt)

var classNames = require("classnames")

const NavLinkComponent = ({ curLocation, lang, url, content }) => {
  return (
    <LinkContainer className="mx-4" to={`/${lang}/${url}`}>
      <NavLink
        className={classNames("navbar-link-custom", {
          underline: curLocation === url,
        })}
      >
        <Translate content={content} />
      </NavLink>
    </LinkContainer>
  )
}

const Header = ({ lang, isAuthenticated, user }) => {
  const [scrollPos, setScrollPos] = useState(window.pageYOffset)
  const [hover, setHover] = useState(false)
  const [value, setValue] = useState(0) // integer state

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    setScrollPos(window.pageYOffset)
  }

  const forceUpdate = () => {
    setValue((value) => ++value)
  }

  const linkStyle = {
    color: "#fff",
    textDecoration: "none",
  }

  let curLocation = window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 1
  ]
  return (
    <header>
      {curLocation !== "login" && curLocation !== "register" && (
        <Navbar
          className={classNames("navbar px-5", {
            "navbar--scrolled": scrollPos !== 0 || hover !== false,
          })}
          fixed="top"
          expand="md"
          variant="dark"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <LinkContainer exact to={`/${lang}/home`}>
            <Nav className="navbar-logo ml-3" style={linkStyle}>
              <img src={logoIMG} alt="logo" />
            </Nav>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto mr-3" onClick={() => forceUpdate()}>
              <NavLinkComponent curLocation={curLocation} lang={lang} url="about" content="Navbar.about" />
              <NavLinkComponent curLocation={curLocation} lang={lang} url="events" content="Navbar.events" />
              <NavLinkComponent curLocation={curLocation} lang={lang} url="team" content="Navbar.team" />
              <NavLinkComponent curLocation={curLocation} lang={lang} url="projects" content="Navbar.projects" />
              {/* Uncomment when contacts page backend is working  */}
              {/* <NavLinkComponent curLocation={curLocation} lang={lang} url="contact" content="Navbar.contact" /> */}

              {isAuthenticated && (
                <NavItem>
                  <Logout />
                </NavItem>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </header>
  )
}

export default compose(withRouter, connect(mapStateToProps, null))(Header)
