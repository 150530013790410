import axios from "axios";
import { returnErrors, clearErrors } from "./errorActions";
import * as Types from "../actions/types";

// Register User
export const register = ({ name, email, password, passwordConfirmation }) => (
  dispatch
) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (password.length < 6) {
    dispatch(
      returnErrors({ msg: "Password must have 6 digits" }, 404, "REGISTER_FAIL")
    );
    dispatch({
      type: Types.REGISTER_FAIL,
    });
    return;
  }

  // Request body
  const body = JSON.stringify({ name, email, password, passwordConfirmation });

  axios
    .post("/api/users", body, config)
    .then((res) => {
      dispatch({
        type: Types.REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      );
      dispatch({
        type: Types.REGISTER_FAIL,
      });
    });
};

// Login User
export const login = ({ email, password }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request body
  const body = JSON.stringify({ email, password });

  axios
    .post("/api/auth", body, config)
    .then((res) => {
      dispatch({
        type: Types.LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
      );
      dispatch({
        type: Types.LOGIN_FAIL,
      });
    });
};

// Logout User
export const logout = () => {
  return {
    type: Types.LOGOUT_SUCCESS,
  };
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers
  if (token) config.headers["x-auth-token"] = token;

  return config;
};

export const updateUser = (id, body) => (dispatch, getState) => {
  if (body.image) {
    const formData = new FormData();
    formData.append("image", body.image);

    // Profile picture update
    axios
      .put(
        `/api/members/${id}/profile-picture`,
        formData,
        tokenConfig(getState)
      )
      .catch((err) =>
        dispatch(returnErrors(err.response.data, err.response.status))
      );
  }

  console.log("body");
  console.log(body);

  // Update User
  axios
    .patch(`/api/members/${id}`, JSON.stringify(body), tokenConfig(getState))
    .then((res) => {
      console.log("Resposta Editar Member");
      console.log(res.data);
      dispatch({
        type: Types.UPDATE_USER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
