import React from 'react'
import { Container, Row, Col } from "react-bootstrap"

import './Member.css'

class Member extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <Container className="d-flex justify-content-center h-100 py-3">
                <div className="direction-card py-3">
                    <Col className="d-flex h-100 flex-column align-items-center text-center">
                        <div className="mb-4">
                            <img className="direction-image" src ={this.props.memberData.picturePath} />
                        </div>
                        <div className="d-flex align-items-center flex-grow-1">
                            <h1>{this.props.memberData.name}</h1>
                        </div>
                        <div>
                            <h2>{this.props.memberData.role}</h2>
                        </div>
                    </Col>
                </div>
        </Container>
        )
    }
}

export default Member