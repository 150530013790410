const langs = {
  Navbar: {
    languages: {
      en: "English",
      pt: "Portuguese",
    },

    about: "About",
    events: "Events",
    members: "Members",
    team: "Team",
    home: "Home",
    projects: "Projects",
    contact: "Contact",
  },

  Home: {
    name: "Home",
    title: "WE ARE NCGM",
    button_text: "Get to know us!",
    ggj_button: "Sign up for the GGJ!",
    recruitment_button: "We're recruiting!",
    we_are_passionate_about: 'We are passionate about',
    what_we_do_label1: 'Workshops: Introduction to a variety of tools related to the group’s fields of interest (Blender, UE4, Unity,…)',
    what_we_do_label2: 'Game Dev Meet: A monthly meetup, open to the general public, aimed towards all who hold an interest in game development.',
    what_we_do_label3: 'Global Game Jam: A 48-hour hackathon focused on game development, taking place worldwide during the early months of the year.',
    our_interests: {
      cgraphics: 'Computer Graphics',
      gamedev: 'Game Development',
      interaction: 'Interface Design',
      multimedia: 'Multimedia'
    },
    interest_descriptions: {
      cgraphics: "This vast, exciting area of computer science is in constant evolution and is the core of digital photography, film, and video games",
      gamedev: "From the simplest 2D indie platformers to the most complex AAA strategy games, in NCGM's heart resides a deep love for Game Dev",
      interaction: "Developing a frictionless, aesthetically pleasing, and clean bridge between the user and the device is, an art we seek to learn",
      multimedia: "At NCGM, we recognize the importance of audiovisual interaction, and the artistic freedom it allows, in any kind of application",
    },
    eventPanel: {
      title: 'Game Dev Meet @ Porto',
      subtitle: 'Event',
      description: {
        p1 : "Our biggest regular event is the Game Dev Meet @Porto, a monthly gathering of amateur or professional creators, or games and multimedia experiments aficionados.",
        p2 : "This event seeks to foment the interaction between creators and admirers, and constitutes a perfect space to share projects, hang out and discuss ideas.",
        p3 : "NCGM began organizing this event in May 2017, and we have an average of 50 participants per session!",
        p4 : "Admission is free, and no previous registration is needed! From sound to graphic designers, writers to artists, programmers or just curious people, anyone can participate, show off their work, learn, teach, and most importantly of all, have fun!"
      },
      button_text: 'See all our Events'
    },
    projectPanel: {
      title: 'FEUP Mayhem',
      subtitle: 'Project',
      description: {
        p1 : "A game for two players based on Gun Mayhem.",
        p2 : "The overall theme is FEUP, and the characters represent different Engineering degrees of the aforementioned college."
      },
      button_text: 'Check Our Projects'
    },
    goals: 'Objectivs',
    who_are_we: 'Who are we?',
    who_are_we_label: 'The Computer Graphics and Multimedia Student Group has historically focused on the field of game development, having over the last few years expanded its sphere of interests to include some of the many facets of Multimedia. As of today, we organise the monthly Game Dev Meet @ Porto and have participated in the Global Game Jam (a game oriented hackathon) as a Jam Site for 3 consecutive years. In addition to events related to game development, we also hold workshops spanning a range of topics relevant to the group’s identity, approaching tools such as Blender and Unity. Ultimately, we seek to offer the general public activities that estimate the growth of multimedia and game development domains on a national level.',    
    ggj_date: 'January 31 - February 2',
    calculate: 'Calculating...',
    ggj_see_more: 'Do you want to know more about Global Game Jam?',
    ggj_check: 'Check our page',
    find: 'Find Us or Contact Us!',
    form_name: 'Name',
    form_textarea: 'Write your message here...',
    form_submit: 'Submit',
    form_sent: 'Sent!'
  },

  About: {
    name: "About",
    title: "WE SEEK TO EXCEL",
    about_us: {
      p1: "Since its inception, NCGM has looked to promote interdisciplinarity, exploring the synergies, convergencies, and possible articulations between a multitude of scientific, technologic, humanistic, and artistic areas. To this end, we have developed countless projects and organized and promoted events inside our fields of activity, directed not only at the student community, but also any enthusiast of these fields.",
    },
    Panel: {
      title: 'Connecting Talents Since 2006',
      subtitle: 'Our History',
      description: {
        p1: "NCGM, founded in 2006 by students with an interest in the areas of Computer Graphics, Game and Digital Experiences Development, and Human-Computer Interaction.",
        p2: "Initially called NeCG, in 2017, we fused with the Student Nucleus of the Multimedia Masters' degree, in order to promote the development of projects with multidisciplinary teams and with the intent of amplifying both nucleus's impact in their respective communities. Sometime after this fusion's officialization, our nucleus assumed the current name of Nucleus of Computer Graphics and Multimedia, or NCGM.",
        p3: "Today, our Nucleus is composed of around 50 members, most of which are students of the Engineering Faculty of the University of Porto, and the remaining students at other faculties in the Porto region.",
        p4: "If you are interested in the fields we operate in, do not hesitate to get in contact with us. We are always looking for driven individuals who share our passion for everything computer graphics and multimedia!",
      },
      button_text: 'Contact Us'
    },
    sutitle: "WE MAKE EVENTS AND PROJECTS IN",
  },

  Members: {
    title: "Our team",
    description: "short description",
    elected: "Elected Members",
    members: "Members",
    president: "President",
    treasurer: "Treasurer",
    vice: "Vice-President",
  },

  Team: {
    name: "Team",
    title: "WE ARE A TEAM OF MAKERS",
    our_team:
    {
      p1: "We prioritize an 'hands-on' approach, when it comes to developing new aptitudes, acquiring experience and learning by doing, resulting not only in our members feeling more engaged but also promoting their creativity and ability to problem-solve.",
    },
    Panel: {
      title: '100+ Members',
      subtitle: 'We are',
      description: {
        p1: 'With a relatively recent history, dating back to 2006, NCGM has looked to attract and to provide a helpful platform to any and all enthusiast interested in Computer Graphics, Game Dev and Multimedia.',
        p2: 'We seek to foment a strong relationship of cooperation and knowledge-sharing between our members. From student to student, or through our alumni, we are committed to create a better process of adaptation of younger students to a world which, to many, can be quite challenging (or even completely alien).',
        p3: 'Want to be our next member? Sign up now!',
      },
      button_text: 'Be a part of it'
    },
  },

  Projects: {
    name: "Projects",
    title: "WE GET THINGS DONE",
    our_projects:
      {
        p1: "This page shows contains some completed projects, developed by members of NCGM.",
      },
    project1Panel: {
      title: 'NCGM\'s Website',
      subtitle: 'Project',
      description: {
        p1: "The comfy place we're in right now.",
        p2: "Come and sit by the hearth.",
      },
      button_text: 'Git Repository',
    },
    project2Panel: {
      title: 'FEUP Mayhem',
      subtitle: 'Project',
      description: {
        p1 : "FEUP Mayhem is a two-player 2D platform shooter inspired by Gun Mayhem and Super Smash Bros.",
        p2 : "The game takes place during a particularly exhausting semester, in which students fight to defend their Engineering degree.",
        p3 : "Players take control of one of 4 available characters, each with a backstory and a special ability based on their respective course, which can turn the course of the battle instantly, when used efficiently!",
        p4 : "They must use their abilities, weapons and wits to gain an edge over their opponent and knock them off the map!",
        p5 : "Bring pride upon your degree and become the ultimate Engineer!"
      },
      button_text: 'Git Repository',
    },
  },

  Events: {
    name: "Events",
    title: "WE CONNECT TALENTED PEOPLE",
    our_events:
      {
        p1: "Our Nucleus hosts workshops and lectures with the intention of improving our members' and associates' capacities and aptitudes, providing them with means to develop projects and activities of value to them, and to the community they belong to.",
      },
    event1Panel: {
      title: 'Game Dev Meet @ Porto',
      subtitle: 'Event',
      description: {
        p1 : "Our biggest regular event is the Game Dev Meet @Porto, a monthly gathering of amateur or professional creators, or games and multimedia experiments aficionados.",
        p2 : "This event seeks to foment the interaction between creators and admirers, and constitutes a perfect space to share projects, hang out and discuss ideas.",
        p3 : "NCGM began organizing this event in May 2017, and we have an average of 50 participants per session!",
        p4 : "Admission is free, and no previous registration is needed! From sound to graphic designers, writers to artists, programmers or just curious people, anyone can participate, show off their work, learn, teach, and most importantly of all, have fun!"
      },
      button_text: 'Learn more'
    },
    event2Panel: {
      title: 'Global Game Jam',
      subtitle: 'Event',
      description: {
        p1: "Participate in the world's largest game jam! Develop an entire game in 48 hours either alone or in a team. At the start of the jam, a theme is given to the participants, and they must create a game based on that!",
        p2: "NCGM hosts a jam site at FEUP, where teams gather to participate in the competition!",
        p3: "The GGJ usually takes place at the around the end of January; its exact date can be found at the Global Game Jam's official website.",
      },
      button_text: 'Check out their website!'
    },
  },

  Footer: {
    message: "Follow us on social media and stay updated.",
  },

  Contact: {
    name: "Contact",
    title: "TALK TO US",
    description: "WE WOULD LOVE TO HEAR FROM YOU!",
    form_first_name: "First Name",
    form_last_name: "Last Name",
    form_textarea: "Your message",
    form_submit: "SEND MESSAGE",
    form_sent: "Sent!",
  },

  Login: {},

  Signup: {},

  Logout: {
    profile: "Edit Profile",
    logout: "Logout",
  },

  Profile: {
    saveChanges: "Save Changes",
    imgTooltip: "Choose an image with the same width and height",
    role1: "Member",
    role2: "Administrator",
    role3: "Alumni",
    aboutMe: "About me:",
    descriptionPlaceholder: "Write your personal description here...",
    editTooltip: "Edit",
    memberSince: "Member since:",
  },

  Ggj: {
    name: "Global Game Jam",
    title: "Join us in the Global Game Jam",
    about_ggj: {
      p1: "Join us in and stay up to date with news about this year's Global Game Jam. We will be participating along several other portuguese teams to create a game in 48 hours.",
      p2: "Due to covid and current restrictions in Portugal, the entire event will take place in online format. Keep reading to learn more.",
    },
    Panel: {
      title: 'Inspiring game creators since 2008',
      subtitle: 'Global Game Jam',
      description: {
        p1: "The Global Game Jam will take place between the 20th and the 30th of January of 2022. In a date yet to be chosen, all portuguese teams will have 48 hours to make the best game they can and present it to everyone. All games have to revolve around a theme. The theme is secret though, so it will only be revealed close to the Game Jam starting time.",
        p2: "Unfortuantely, due to restrictions imposed to stop the spread of the Sars-CoV2 virus, the entire Game Jam will take place in online format. Participants will have access to a private Discord server where they can join their team and participate in the Game Jam.",
      },
    },
    sutitle: "Meet the team!",
  },

  // GGJ: {
  //   info: "Quick Links and Information",
  //   officialWebsite: "Official Page:",
  //   datesLabel: "Dates:",
  //   dates: "31 January - 02 February , 2020",
  //   jameSitesLabel: "Jam Sites:",
  //   jamesites: "List of Global Game Jam Locations",
  //   generalContactLabel: "General Contact:",
  //   officialHashtagLabel: "Official hashtag:",
  //   officialHashtag: "#GGJ20",
  //   countdown: "Countdown",
  //   ourSite: "About our Site",
  //   whatDoYouNeed: "What do you need to do?",
  //   whatDoWeExcept: "What do we except from you?",
  //   applications: "Applications",
  // },

  UnderConstruction: {
    title: "PAGE UNDER CONSTRUCTION",
    home: "> Home <",
  },

  err404: {
    description: "SORRY, THIS PAGE IS NOT AVAILABLE NOW",
    message: "Whoopsie! The page you are looking for could not be found. You may have mistyped the address or the page may have moved.",
    button_send_back: "TAKE ME BACK",
  },
};

export default langs;

// module.exports = {
//   example: {
//     switch_language: 'Switch Language: ',

//     languages: {
//       en: 'English',
//       de: 'German'
//     },

//     headline: 'Listing People',

//     person_age_sentence: {
//       zero:   '%(firstName)s is not even a year old.',
//       one:    '%(firstName)s is one year old.',
//       other:  '%(firstName)s is %(count)s years old.'
//     },

//     seconds_passed: {
//       one:    '<b>One second</b> has passed since requesting this page.',
//       other:  '<b>%(count)s seconds</b> have passed since requesting this page.'
//     },

//     locale_prop_text: 'Although this sentence is fetched from translation data, it does not change when switching the language because the <i>locale is hard-coded as a prop</i> here.',

//     click_me: 'Click me!',
//     you_clicked_on: 'You clicked on %(what)s.'
//   }
// };

// return <Translate component="p" with={{ count: this.state.seconds }} content="example.seconds_passed" unsafe />;
