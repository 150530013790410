import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import "./Social.css";

const Social = () => {
  return (
    <div className="vertical-center" id="sticky-social">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/ncgmfeup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-stack">
              <FontAwesomeIcon
                className="fab fa-stack-1x cl-social"
                icon={faFacebookSquare}
              />
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/necgm/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-stack">
              <FontAwesomeIcon
                className="fab fa-stack-1x cl-social"
                icon={faLinkedin}
              />
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/ncgmfeup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-stack">
              <FontAwesomeIcon
                className="fab fa-stack-1x cl-social"
                icon={faInstagram}
              />
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UC5zqeMTr3AY-jS3C239YTEg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-stack">
              <FontAwesomeIcon
                className="fab fa-stack-1x cl-social"
                icon={faYoutube}
              />
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
