import React, { useRef, useEffect } from "react"
import Translate from "react-translate-component"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import bannerBackground from "../../assets/images/page-bg/bg-ncgm-projects.jpg"

import Footer from "../../components/footer"
import Social from "../../components/social"
import "./Projects.css"

import Panel from "../../components/panel"
import SimpleText from "../../components/simple-text"

import project1Panel from "../../assets/images/projects/website-NCGM.jpg"
import project2Panel from "../../assets/images/projects/feup-mayhem.png"

const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "cover",
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)

const Projects = (props) => {
  const firstView = useRef(null)
  const executeScroll = () => scrollToRef(firstView)


  const ourprojectsSimpleTextProps = {
    description: "Events.our_events",
    descriptionParNum: 1,
  }

  const project1PanelProps = {
    images: [project1Panel],
    title: "Projects.project1Panel.title",
    subtitle: "Projects.project1Panel.subtitle",
    description: "Projects.project1Panel.description",
    descriptionParNum: 2,
    button_text: "Projects.project1Panel.button_text",
    buttonOnClick: () => window.location.href = "https://github.com/necg/website-mern",
  }

  const project2PanelProps = {
    images: [project2Panel],
    title: "Projects.project2Panel.title",
    subtitle: "Projects.project2Panel.subtitle",
    description: "Projects.project2Panel.description",
    descriptionParNum: 5,
    button_text: "Projects.project2Panel.button_text",
    buttonOnClick: () => window.location.href = "https://github.com/necg/summer-project-2020",
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container fluid>
      <Row className="one-page-plus">
        <Col className="col-lg-6 col-12 col-sm-12 default-banner">

          <div className="main-title-no-bg">
            <Translate
              component="div"
              className="main-title"
              content="Projects.title"
            />
          </div>

          <div className="main-description-no-bg mt-2">
            <SimpleText
              simpletext={ourprojectsSimpleTextProps}
            ></SimpleText>
          </div>


        </Col>

        <div className="bg-img">
          <div className="w-100 h-100" style={bannerImg} />
        </div>

        <a className="scroll-icon" onClick={executeScroll}>
          <FontAwesomeIcon icon={("fas", faChevronDown)} />
        </a>

      </Row>

      <Row ref={firstView} className="bg-color-2 py-5">
        <Col>
          <Panel
            className="mb-4"
            Panel={project1PanelProps}
            bannerPos="left"
          ></Panel>
        </Col>

        <Col className="col-12 col-sm-12">
          <Panel
            className="my-3"
            Panel={project2PanelProps}
            bannerPos="right"
          />
        </Col>
      </Row>

      <Footer {...props} />
      <Social />
    </Container>
  )
}

export default Projects
