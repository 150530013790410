import React from "react";

import "./SimpleText.css";

import Translate from "react-translate-component";

class SimpleText extends React.Component {
    constructor(props) {
      super(props);
      this.descriptionElems = [];
  
      this.style = {
        description: {},
      };
  
      this.setStyles();
      this.prepDescription();
    }
  
    setStyles() {
      if (this.props.style) {
        if (this.props.style.description)
          this.style.description = this.props.style.description;
      }
    }
  
    prepDescription(){
      for(let i = 0; i < this.props.simpletext.descriptionParNum; i++){
        this.descriptionElems.push(<Translate
          key={i}
          className="simple-text"
          style={this.style.description}
          component="p"
          content={`${this.props.simpletext.description}.p${i + 1}`}
          />)
        }
    }
  
    render() {
  
      return (
        <div className="simple-text">
              {this.descriptionElems}
        </div>
      );
    }
  }
  
  export default SimpleText;
  