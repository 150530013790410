import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Translate from "react-translate-component"

import Social from "../../components/social"
import Footer from "../../components/footer"
import bannerBackground from "../../assets/images/page-bg/bg-ncgm-contact.svg"
import "./Contact.css"

const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "contain",
}

const Contact = (props) => {
  console.log(props)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container fluid>
      <Row className="one-page-plus-minus-footer contact-bg-color">
        <Col className="col-lg-6 col-12 col-md-12 col-sm-12">
          <Translate
            component="div"
            className="main-title-no-bg title-top"
            content="Contact.title"
          />
          <div className="padding-contact">
            <Translate
              component="p"
              content="Contact.description"
            />
          </div>
          {/* TODO form action */}
          <div className="contact-form">
            <form id="email-form" method="POST" action="">
              <div className="first-last-name">
                <Row>
                  <Col className="col-lg-6 col-sm-12 col-md-12 col-12">
                    <Translate
                      component="input"
                      className="form-control form-input"
                      type="fname"
                      id="fname"
                      name="fname"
                      rows="1"
                      maxLength="30"
                      required
                      attributes={{ placeholder: "Contact.form_first_name" }}
                    />
                  </Col>
                  <Col className="col-lg-6 col-sm-12 col-md-12 col-12">
                    <Translate
                      component="input"
                      className="form-control form-input"
                      type="lname"
                      id="lname"
                      name="lname"
                      rows="1"
                      maxLength="30"
                      required
                      attributes={{ placeholder: "Contact.form_last_name" }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group my-5">
                <input
                  className="form-control form-input"
                  type="email"
                  id="email"
                  name="email"
                  maxLength="35"
                  required
                  placeholder="E-mail"
                />
              </div>
              <div className="form-group my-5">
                <Translate
                  component="textarea"
                  className="form-control form-input"
                  id="message"
                  name="message"
                  rows="1"
                  maxLength="500"
                  required
                  attributes={{ placeholder: "Contact.form_textarea" }}
                />
              </div>
              <Translate
                component="button"
                className="btn btn-danger form-submit"
                type="submit"
                content="Contact.form_submit"
              />
              <div className="hidden" id="form-element">
                <Translate
                  component="p"
                  className="mt-2 mb-0"
                  content="Contact.form_sent"
                />
              </div>
            </form>
          </div>
        </Col>
        <Col className="col-lg-6 banner-half-page-img-2x banner-mobile-img">
          <div className="w-100 h-100" style={bannerImg}></div>
        </Col>
      </Row>
      <Footer {...props} />
      <Social />
    </Container>
  )
}

export default Contact
