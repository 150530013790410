import * as Types from "../actions/types"

const getInitialState = () => {
  const token = localStorage.getItem("necgm-token")
  const user = JSON.parse(localStorage.getItem("necgm-user"))
  if (token) {
    return {
      isAuthenticated: true,
      token,
      user,
      isLoading: false,
    }
  }
  return {
    isAuthenticated: false,
    token: "",
    user: null,
    isLoading: false,
  }
}

function state(state = getInitialState(), action) {
  switch (action.type) {
    case Types.USER_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case Types.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      }
    case Types.LOGIN_SUCCESS:
    case Types.REGISTER_SUCCESS:
      localStorage.setItem("necgm-token", action.payload.token)
      localStorage.setItem("necgm-user", JSON.stringify(action.payload.user))
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
      }
    case Types.AUTH_ERROR:
    case Types.LOGIN_FAIL:
    case Types.LOGOUT_SUCCESS:
    case Types.REGISTER_FAIL:
      localStorage.removeItem("necgm-token")
      localStorage.removeItem("necgm-user")
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      }
    case Types.UPDATE_USER:
      localStorage.setItem("necgm-user", JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      }
    case Types.GET_PROFILE_PICTURE:
      return {
        ...state,
        user: {
          ...state.user,
          profilePicture: action.payload.profilePicture,
        },
        isLoading: false,
      }
    default:
      return state
  }
}

export default state