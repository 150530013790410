import React, { useRef, useEffect } from "react"
import Translate from "react-translate-component"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import bannerBackground from "../../assets/images/page-bg/bg-ncgm-about.svg"
import { useHistory } from "react-router-dom"

import Footer from "../../components/footer"
import Social from "../../components/social"
import "./Ggj.css"

import Card from "../../components/card"
import Panel from "../../components/panel"
import SimpleText from "../../components/simple-text"
import feupPanel from "../../assets/images/feup-banner.svg"

// Interests Card SVGs
import cgraphicsSVG from "../../assets/images/cards/cgraphics.svg"
import gamedevSVG from "../../assets/images/cards/gamedev.svg"
import interactionSVG from "../../assets/images/cards/interaction.svg"
import multimediaSVG from "../../assets/images/cards/multimedia.svg"

const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "cover",
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)

const Ggj = (props) => {
  const history = useHistory()
  const firstView = useRef(null)
  const executeScroll = () => scrollToRef(firstView)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const ggjPanelProps = {
    images: [feupPanel],
    title: "Ggj.Panel.title",
    subtitle: "Ggj.Panel.subtitle",
    description: "Ggj.Panel.description",
    descriptionParNum: 2,
    /*button_text: "Ggj.Panel.button_text",
    buttonOnClick: () => history.push(`/${props.lang}/contact`),*/
  }

  const ggjusSimpleTextProps = {
    description: "Ggj.about_ggj",
    descriptionParNum: 2,
  }

  const cgraphicsCardProps = {
    title: "Home.our_interests.cgraphics",
    titleCentered: true,
  }

  const gamedevCardProps = {
    title: "Home.our_interests.gamedev",
    titleCentered: true,
  }

  const interactionCardProps = {
    title: "Home.our_interests.interaction",
    titleCentered: true,
  }

  const multimediaCardProps = {
    title: "Home.our_interests.multimedia",
    titleCentered: true,
  }

  return (
    <Container fluid>
      <Row className="one-page-plus team-bg-color">
        <Col className="col-lg-6 col-12 col-sm-12 default-banner">

          <div className="main-title-no-bg">
            <Translate
              component="div"
              content="Ggj.title"
            />
          </div>

          <div className="main-description-no-bg mt-2">
            <SimpleText
              simpletext={ggjusSimpleTextProps}
            ></SimpleText>
          </div>

        </Col>

        <div className="col-lg-6 events-banner-img">
          <div className="w-100 h-100" style={bannerImg} />
        </div>

        <a className="scroll-icon" onClick={executeScroll}>
          <FontAwesomeIcon icon={("fas", faChevronDown)} />
        </a>

      </Row>

      <Row
        ref={firstView}
        className="mb-2 bg-color-1 py-5 half-page-plus-minus-nav"
      >
        <Col>
          <Panel
            className="mb-4"
            Panel={ggjPanelProps}
            bannerPos="left"
          ></Panel>
        </Col>
      </Row>

      {/*<Row className="bg-color-2 card-list half-page-plus-minus-nav">
        <Col className="col-12 col-sm-12">
          <Translate
            component="h2"
            className="card-list-title mx-1"
            content="Ggj.sutitle"
          />
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 offset-xl-2">
          <Card
            card={cgraphicsCardProps}
            svgImg={cgraphicsSVG}
          ></Card>
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
          <Card
            card={gamedevCardProps}
            svgImg={gamedevSVG}
          ></Card>
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
          <Card
            card={interactionCardProps}
            svgImg={interactionSVG}
          ></Card>
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
          <Card
            card={multimediaCardProps}
            svgImg={multimediaSVG}
          ></Card>
        </Col>
  </Row>*/}

      <Footer {...props} />
      <Social />
    </Container>
  )
}

export default Ggj
