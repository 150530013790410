import axios from 'axios';
import { returnErrors, clearErrors } from './errorActions';
import { tokenConfig } from './authActions';
import * as types from './types';


export const fetchMembers = () => (dispatch) => {
  dispatch(setMembersLoading());
  axios
    .get('/api/members')
    .then(res =>
      dispatch({
        type: types.GET_MEMBERS,
        payload: res.data
      })
    );
};

export const fetchMemberMails = () => (dispatch, getState) => {
  dispatch(setMemberEmailsLoading());
  axios
    .get("/api/memberEmails", tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: types.GET_MEMBER_EMAILS,
        payload: res.data
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

// Register Permmited email
export const registerEmail = ({ email }) => (dispatch, getState) => {
  // Request body
  const body = JSON.stringify({ email });

  axios
    .post("/api/memberEmails", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: types.REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      );
      dispatch({
        type: types.REGISTER_FAIL,
      });
    });
};

export const setMembersLoading = () => {
  return {
    type: types.MEMBERS_LOADING
  };
};

export const setMemberEmailsLoading = () => {
  return {
    type: types.MEMBER_EMAILS_LOADING
  };
}

export const setProfilePicLoading = () => {
  return {
    type: types.MEMBER_PROFILE_PIC_LOADING
  };
}
