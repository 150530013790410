import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import './Card.css'

import threeDots from '../../assets/images/cards/3dots.svg'
import Translate from 'react-translate-component'


class Card extends React.Component {

    constructor(props) {
        super(props)
        this.imgTags = []

        this.style = {
            subtitle: {},
            title: {},
            description: {},
        }

        this.setStyles()
    }

    setStyles() {
        if (this.props.style) {
          if (this.props.style.image)
            this.style.image = this.props.style.image
    
          if (this.props.style.title) this.style.title = this.props.style.title
    
          if (this.props.style.description)
            this.style.description = this.props.style.description
        }
      }

    render() {
      return (
        <Container className="card-container col-12">
            <Row>
                <Col className="card-img">
                    <img style={this.style.image} alt="Card" src={this.props.svgImg} />
                    {/* TODO: figure out why this doesn't work */}
                    {/* <img style={this.style.image} alt="Card" src={this.props.card.svgImg} /> */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Translate className={this.props.card.titleCentered !== undefined ? "text-center my-2 pb-4" : ""} style={this.style.title} component="h2" content={this.props.card.title} />
                </Col>
            </Row>
            <Row>
                <Col className="card-desc" style={this.style.description}>
                    <Translate
                        className="card-desc"
                        style={this.style.description}
                        component="p"
                        content={this.props.card.description}
                    />
                </Col>
            </Row>
            {this.props.card.threeDots !== undefined ?
                (<Row className="three-dots">
                    <Col>
                        <img src={threeDots} alt="Bottom" />
                    </Col>
                </Row>
                ) : ''
            }
        </Container>
      )
    }
  }

export default Card;