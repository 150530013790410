import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import Translate from "react-translate-component"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import { connect } from "react-redux"
import { fetchMembers } from "../../actions/memberActions"
import { setLanguage } from "../../actions/langActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons"
import { languageWrap } from "../../components/lang/language-wrap"

import bannerBackground from "../../assets/images/page-bg/bg-ncgm-team.svg"
import profileImg from "../../assets/images/Mariana.png"
import Footer from "../../components/footer"
import Social from "../../components/social"
import "./Team.css"

import Panel from "../../components/panel"
import SimpleText from "../../components/simple-text"
import Member from "../../components/member"
import topBanner from "../../assets/images/team/anniversary2-vr.svg"
import bottomBanner from "../../assets/images/team/anniversary2-fbmaq.svg"

import Presidente from "../../assets/images/direcao/presidente.jpg"
import VicePresidente from "../../assets/images/direcao/vice_presidente.jpg"
import Tesoureiro from "../../assets/images/direcao/tesoureiro.jpg"
import AGPresidente from "../../assets/images/direcao/ag_presidente.jpg"
import AGSecretario1 from "../../assets/images/direcao/ag_secretario1.jpg"
import AGSecretario2 from "../../assets/images/direcao/ag_secretario2.jpg"
import CFPresidente from "../../assets/images/direcao/cf_presidente.jpg"
import CFSecretario from "../../assets/images/direcao/cf_secretario.jpg"
import CFVogal from "../../assets/images/direcao/cf_vogal.jpg"

const membersData = [
  {
    name: "Eduardo Gonçalves",
    role: "Vice-presidente",
    bio: "bio1",
    picturePath: VicePresidente,
  },
  {
    name: "Tomás Carmo",
    role: "Presidente",
    bio: "bio1",
    picturePath: Presidente,

  },
  {
    name: "António Matos",
    role: "Tesoureiro",
    email: "email3",
    bio: "bio3",
    picturePath: Tesoureiro,
  },
  {
    name: "Rita Lopes",
    role: "1ª secretária da MAG",
    email: "email3",
    bio: "bio3",
    picturePath: AGSecretario1,
  },
  {
    name: "Tomás Moreira",
    role: "Presidente da MAG",
    email: "email3",
    bio: "bio3",
    picturePath: AGPresidente,
  },
  {
    name: "Tiago Ribeiro",
    role: "2º secretário da MAG",
    email: "email3",
    bio: "bio3",
    picturePath: AGSecretario2,
  },
  {
    name: "Ana Ramos",
    role: "Secretária do Conselho Fiscal",
    email: "email3",
    bio: "bio3",
    picturePath: CFSecretario,
  },
  {
    name: "Clarisse Carvalho",
    role: "Presidente do Conselho Fiscal",
    email: "email3",
    bio: "bio3",
    picturePath: CFPresidente,
  },
  {
    name: "Gonçalo Barroso",
    role: "Vogal do Concelho Fiscal",
    email: "email3",
    bio: "bio3",
    picturePath: CFVogal,
  }
]


const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "contain",
}

const ourteamSimpleTextProps = {
  description: "Team.our_team",
  descriptionParNum: 1,
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)

const Team = (props) => {
  const history = useHistory()
  const [members, setMembers] = useState([])
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);

  const firstView = useRef(null)
  const executeScroll = () => scrollToRef(firstView)

  const PanelStyle = {
    title: {
      fontStyle: "normal",
      fontWeight: 900,
      lineHeight: "100%",
      textTransform: "uppercase",
    },
    subtitle: {
      color: "white",
      opacity: 1,
      fontWeight: "bolder",
    },
  }

  const teamPanelProps = {
    images: [topBanner, bottomBanner],
    title: "Team.Panel.title",
    subtitle: "Team.Panel.subtitle",
    description: "Team.Panel.description",
    descriptionParNum: 3,
    button_text: "Team.Panel.button_text",
    buttonOnClick: () => history.push(`/${props.lang}/contact`),
  }

  useEffect(() => {
    const fetchData = async () => {
      // setError(false);
      // try {
      //   setLoading(true);
      //   props.fetchMembers();
      //   setLoading(false);
      // } catch (e) {
      //   setLoading(false);
      //   setError(true);
      // }

      props.fetchMembers()
    }
    fetchData()
    window.scrollTo(0, 0)
    languageWrap(props)
  }, [])

  useEffect(() => {
    setMembers(props.members.members)
  }, [props.members])

  return (
    <Container fluid>

      <Row className="one-page-plus team-bg-color">

        <Col className="col-lg-6 col-12 col-sm-12 team-banner">

          <div className="main-title-no-bg">
            <Translate
              component="div"
              content="Team.title"
            />
          </div>
          
          <div className="main-description-no-bg">
            <div>
              <SimpleText
                simpletext={ourteamSimpleTextProps}
              ></SimpleText>
            </div>
          </div>
        </Col>

        <Col className="col-lg-6 team-banner-img">
          <div className="w-100 h-100" style={bannerImg}></div>
        </Col>
      </Row>

      <div>
        <a className="scroll-icon" onClick={executeScroll}>
          <FontAwesomeIcon icon={("fas", faChevronDown)} />
        </a>
      </div>
      
      <Row ref={firstView} className="bg-color-1">
        <Col className="col-12">
          <Panel
            className="my-5 py-5 mx-0 px-0"
            Panel={teamPanelProps}
            style={PanelStyle}
            bannerPos="left"
          />
        </Col>
      </Row>

      <div className="bg-color-2 text-white py-5">
        {/* Title */}
        <Row className="justify-content-center">
          <h1>
            <Translate
              component="small"
              className="mx-2"
              content="Members.title"></Translate>
          </h1>
        </Row>
        
        {/* 3 Member rows */}
        <Row className="justify-content-center">
          <Row className="w-75 my-4">
            <Col>
              <Member memberData={membersData[0]}></Member>
            </Col>

            <Col>
              <Member memberData={membersData[1]}></Member>
            </Col>

            <Col>
              <Member memberData={membersData[2]}></Member>
            </Col>

          </Row>

          <Row className="w-75 my-4">
            <Col>
              <Member memberData={membersData[3]}></Member>
            </Col>

            <Col>
              <Member memberData={membersData[4]}></Member>
            </Col>

            <Col>
              <Member memberData={membersData[5]}></Member>
            </Col>
          </Row>

          <Row className="w-75 my-4">
            <Col>
              <Member memberData={membersData[6]}></Member>
            </Col>

            <Col>
              <Member memberData={membersData[7]}></Member>
            </Col>

            <Col>
              <Member memberData={membersData[8]}></Member>
            </Col>
          </Row>
        </Row>
        </div>

      <Footer {...props} />
      <Social />
    </Container>
  )
}

Team.propTypes = {
  fetchMembers: PropTypes.func.isRequired,
  members: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  members: state.members,
  lang: state.lang,
})

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(setLanguage(lang)),
  fetchMembers: () => dispatch(fetchMembers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Team)
