import React, { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import { Button } from "reactstrap"
import { connect } from "react-redux"

import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../components/lang/language-wrap"
import "react-awesome-slider/dist/styles.css"
import PropTypes from "prop-types"

import Footer from "../../components/footer"
import Card from "../../components/card"

import "./Home.css"
import Social from "../../components/social"
import Translate from "react-translate-component"

import bannerBackground from "../../assets/images/page-bg/bg-ncgm-home.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"

// Interests Card SVGs
import cgraphicsSVG from "../../assets/images/cards/cgraphics.svg"
import gamedevSVG from "../../assets/images/cards/gamedev.svg"
import interactionSVG from "../../assets/images/cards/interaction.svg"
import multimediaSVG from "../../assets/images/cards/multimedia.svg"

import eventsPanel from "../../assets/images/events/gdm-panel.png"
import projectsPanel from "../../assets/images/projects/feup-mayhem.png"

import Panel from "../../components/panel"

const bannerImg = {
  padding: "0",
  background: `url(${bannerBackground}) no-repeat center center `,
  backgroundSize: "cover",
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)

const Home = (props) => {
  const history = useHistory()
  const firstView = useRef(null)
  const executeScroll = () => scrollToRef(firstView)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const eventPanelProps = {
    images: [eventsPanel],
    title: "Home.eventPanel.title",
    subtitle: "Home.eventPanel.subtitle",
    description: "Home.eventPanel.description",
    descriptionParNum: 4,
    button_text: "Home.eventPanel.button_text",
    buttonOnClick: () => history.push(`/${props.lang}/events`),
  }

  const projectPanelProps = {
    images: [projectsPanel],
    title: "Home.projectPanel.title",
    subtitle: "Home.projectPanel.subtitle",
    description: "Home.projectPanel.description",
    descriptionParNum: 2,
    button_text: "Home.projectPanel.button_text",
    buttonOnClick: () => history.push(`/${props.lang}/projects`),
  }

  const cgraphicsCardProps = {
    title: "Home.our_interests.cgraphics",
    description: "Home.interest_descriptions.cgraphics",
    threeDots: true,
  }

  const gamedevCardProps = {
    title: "Home.our_interests.gamedev",
    description: "Home.interest_descriptions.gamedev",
    threeDots: true,
  }

  const interactionCardProps = {
    title: "Home.our_interests.interaction",
    description: "Home.interest_descriptions.interaction",
    threeDots: true,
  }

  const multimediaCardProps = {
    title: "Home.our_interests.multimedia",
    description: "Home.interest_descriptions.multimedia",
    threeDots: true,
  }

  return (
    <Container fluid>
      <Row className="one-page-plus relative">
        <Col className="col-lg-6 col-12 col-sm-12 default-banner" >

          <div className="main-title-no-bg">
            <Translate
              component="div"
              content="Home.title"
            />
          </div>

          <div className="main-description-no-bg">
            <div className="c-inline">

              <div className="main-description">
                <Button
                  outline
                  color="danger"
                  className="mt-2"
                  onClick={() => history.push(`/${props.lang}/about`)}
                >

                  <div className="button-text-icon text-white mr-2 py-2 px-3">
                    <Translate
                      component="div"
                      content="Home.button_text"
                    />
                  </div>

                  <div className="button-text-icon text-white">
                    <FontAwesomeIcon icon={("fas", faLongArrowAltRight)} />
                  </div>

                </Button>{" "}
              </div>

              {/* Global Game Jam */}
              {/* <div className = "main-description-"> 
                <Button
                  outline
                    color = "danger"
                    className = "mt-2"
                    onClick = {() => history.push(`/${props.lang}/ggj`)}>
                  <div className="button-text-icon text-white mr-2 py-2 px-3">
                      <Translate
                        component = "div"
                        content = "Home.ggj_button"
                      />
                  </div>

                  <div className="button-text-icon text-white">
                    <FontAwesomeIcon icon={("fas", faLongArrowAltRight)} />
                  </div>
                </Button>{" "}
              </div> */}

              {/* Recruitment */}
	      {/*
              <div className="main-description">
                <Button
                  outline
                  color="warning"
                  className="mt-2"

                  onClick={() => window.location.href = ("https://forms.gle/2iGMXnpFcVxk78H29")}
                >

                  <div className="button-text-icon text-white mr-2 py-2 px-3">
                    <Translate
                      component="div"
                      content="Home.recruitment_button"
                    />
                  </div>

                  <div className="button-text-icon text-white">
                    <FontAwesomeIcon icon={("fas", faLongArrowAltRight)} />
                  </div>

                </Button>{" "}
              </div>
	      */}
            </div>

          </div>

        </Col>

        <div className="bg-img">
          <div className="w-100 h-100" style={bannerImg} />
        </div>

        <a className="scroll-icon" onClick={executeScroll}>
          <FontAwesomeIcon icon={("fas", faChevronDown)} />
        </a>

      </Row>

      <Row
        ref={firstView}
        className="bg-color-1 card-list half-page-plus-minus-nav"
      >

        <Col className="col-12 col-sm-12">
          <Translate
            component="h2"
            className="card-list-title mx-1"
            content="Home.we_are_passionate_about"
          />
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 offset-xl-2">
          <Card
            card={cgraphicsCardProps}
            svgImg={cgraphicsSVG}
          ></Card>
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
          <Card
            card={gamedevCardProps}
            svgImg={gamedevSVG}
          ></Card>
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
          <Card
            card={interactionCardProps}
            svgImg={interactionSVG}
          ></Card>
        </Col>

        <Col className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2">
          <Card
            card={multimediaCardProps}
            svgImg={multimediaSVG}
          ></Card>
        </Col>
      </Row>

      <Row className="bg-color-2 py-5">
        <Col className="col-12 col-sm-12">
          <Panel
            className="mt-3 pb-5"
            Panel={eventPanelProps}
            bannerPos="left"
          />
        </Col>

        <Col className="col-12 col-sm-12">
          <Panel
            className="my-3"
            Panel={projectPanelProps}
            bannerPos="right"
          />
        </Col>
      </Row>

      <Social />
      <Footer {...props} />
    </Container>
  )
}

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
