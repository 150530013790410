import counterpart from "counterpart";
import { setLanguage } from "../../actions/langActions";

export const languageWrap = (props) => {
  let { lang } = props.match.params;

  lang = lang === "en" || lang === "pt" ? lang : "pt";
  props.setLanguage(lang);

  const path = props.location.pathname.split("/");
  props.history.push(`/${lang}/${path[path.length - 1]}`);

  counterpart.setLocale(lang);
};

export const mapStateToProps = ({ auth, lang }) => ({
  isAuthenticated: auth.isAuthenticated,
  lang,
});

export const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(setLanguage(lang)),
});
